<router-outlet>
    <ngx-loading [show]="loading" ></ngx-loading>
</router-outlet>
<ngx-loading-bar color="#a3fbe9" height="4px" diameter="40px"></ngx-loading-bar>
<notifier-container></notifier-container>

<ng-template #timeUp let-modal>
<!--<div class="modal" [hidden]="timeUp" id="timeups">-->
  <div class="modal-header2">
    <div class="row col-12">
      <div class="col-8">
        <p class="p-timer mr-2">
          Session is about to expire !
        </p>
      </div>
      <div class="col-4">
        <app-timer></app-timer>
      </div>
    </div>
  </div>
  <div class="modal-body">
    <div class="row container">
      <label style="font-size:18px" *ngIf="sessionOut==0">
        Your login session is going to expire. Do you want to continue ?
      </label>
      <label style="font-size:18px" *ngIf="sessionOut==1">
        Your login session expired.
      </label>
    </div>
    <div class="row">
      <div class="w-100 d-flex justify-content-end">
        <ng-container *ngIf="sessionOut==0">
          <button class="btn btn-md btn-success mr-2" (click)="reLogOn()">Continue</button>
          <button class="btn btn-md btn-danger mr-3" (click)="logOffUser(1)">Logout</button>
        </ng-container>
        <ng-container *ngIf="sessionOut==1">
          <button class="btn btn-md btn-info mr-3" (click)="logOffUser(1)">Login</button>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>
