import * as i0 from "@angular/core";
var PasswordService = /** @class */ (function () {
    function PasswordService() {
        this.numCheck = /\d/;
        this.smAlphCheck = /[a-z]/;
        this.capAlphCheck = /[A-Z]/;
        this.spCharCheck = /[!”#$%&()*+,-./:;<=>?@[\]^_`{|}~]/;
        this.testFlag = 0;
    }
    PasswordService.prototype.validatePassword = function (password) {
        console.log("password - ", password);
        if (this.numCheck.test(password) == false) {
            return { status: 'error', msg: 'Passwords must have at least one numeric character' };
        }
        else if (this.smAlphCheck.test(password) == false) {
            return { status: 'error', msg: 'Passwords must have at least one lowercase alphabetical character' };
        }
        else if (this.capAlphCheck.test(password) == false) {
            return { status: 'error', msg: 'Passwords must have at least one uppercase alphabetical character' };
        }
        else if (this.spCharCheck.test(password) == false) {
            return { status: 'error', msg: 'Passwords must have at least one special character' };
        }
        else if (password.trim().length < 8) {
            return { status: 'error', msg: 'Passwords must have minimum 8 characters' };
        }
        else {
            return { status: 'success', msg: '' };
        }
    };
    PasswordService.ngInjectableDef = i0.defineInjectable({ factory: function PasswordService_Factory() { return new PasswordService(); }, token: PasswordService, providedIn: "root" });
    return PasswordService;
}());
export { PasswordService };
