import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '@env/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
// const endpoint = 'http://192.168.0.11/hms/server/';
// const endpoint = 'http://wiktait.com/demo/hms/server/';
var httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};
var DentalTreatmentService = /** @class */ (function () {
    function DentalTreatmentService(http) {
        this.http = http;
    }
    DentalTreatmentService.prototype.extractData = function (res) {
        var body = res;
        return body || {};
    };
    DentalTreatmentService.prototype.saveDentalTreatment = function (opData) {
        var _this = this;
        // console.log("11111111111111111111111111");
        return this.http.post(environment.API_ENDPOINT + 'DentalTreatment/saveDentalTreatment', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('saveDentalTreatment'));
        }));
    };
    DentalTreatmentService.prototype.listDentalTreatments = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'DentalTreatment/listDentalTreatments', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('listDentalTreatments'));
        }));
    };
    // listDentalDepartments (): Observable<any> {
    //   return this.http.post<any>(environment.API_ENDPOINT + 'DentalTreatment/listDentalDepartments', null).pipe(
    //     tap((result) =>
    //       catchError(this.handleError<any>('listDentalDepartments'))
    //     ));
    // }
    // To handle error
    DentalTreatmentService.prototype.handleError = function (operation, result) {
        if (operation === void 0) { operation = 'operation'; }
        return function (error) {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead
            // TODO: better job of transforming error for user consumption
            console.log(operation + " failed: " + error.message);
            // Let the app keep running by returning an empty result.
            return of(result);
        };
    };
    DentalTreatmentService.ngInjectableDef = i0.defineInjectable({ factory: function DentalTreatmentService_Factory() { return new DentalTreatmentService(i0.inject(i1.HttpClient)); }, token: DentalTreatmentService, providedIn: "root" });
    return DentalTreatmentService;
}());
export { DentalTreatmentService };
