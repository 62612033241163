import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Router } from '@angular/router';
import * as moment from "moment";
import {timeout} from "@env/environment";
@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (localStorage.getItem('isLoggedin')) {
            // return true;
          const lastLog = localStorage.getItem('activeTime');

            if (lastLog !== null && lastLog !== '') {

                const last = moment(lastLog);
                const date =  moment(new Date());
                var duration = moment.duration(date.diff(last));
                var hours = duration.asMinutes();

                // if(hours >= ((timeout.LOGOUT)/60)){ //60
                let key = localStorage.getItem('tokey')?localStorage.getItem('tokey'):''
                let val = localStorage.getItem('valtok')?localStorage.getItem('valtok'):''
                if(val!='' && key!=''&& val != this.getCookie(key)){ //60
                  this.router.navigate(['/login']);
                }
            } else {
                window.location.reload();
                this.router.navigate(['/login'],null);
                return false;
            }
            const path = route.data['path'] as string;
            const level = route.data['level'] as number;
            if (level > 0) {
                if (level === 1) {


                    const modules = JSON.parse(localStorage.getItem('modules'));
                    const module_group =  modules.find(x => x.MODULE_GROUP_PATH === path);

                    if (module_group !== undefined) {

                        if (module_group.MODULE_GROUP_ACCESS === '1') {

                            localStorage.setItem('sub_modules', JSON.stringify(module_group.sub_menu));
                            return true;
                        } else {
                            this.router.navigate(['/access-denied']);
                            return false;
                        }
                    } else {
                        this.router.navigate(['/access-denied']);
                        return false;
                    }
                }
                if (level === 2) {
                    const sub_modules = JSON.parse(localStorage.getItem('sub_modules'));
                    const access_types = JSON.parse(localStorage.getItem('access_types'));
                    const module =  sub_modules.find(x => x.MODULE_PATH === path);


                    if (module !== undefined) {
                        let rights_array: any = [];
                        const user_rights_array: any = {};
                        rights_array = module.MODULE_ACCESS_RIGHTS.split('');
                        for (let i = 0; i < access_types.length; i++) {
                           // if (rights_array.indexOf(i) > -1) {
                                // do stuff with array
                                user_rights_array[access_types[i].USER_ACCESS_TYPE_NAME] = rights_array[i];
                           // }
                        }

                        if (user_rights_array.VIEW === '1') {
                            localStorage.setItem('user_rights', JSON.stringify(user_rights_array));
                            return true;
                        } else {
                            this.router.navigate(['/access-denied']);
                            return false;
                        }
                    } else {
                        this.router.navigate(['/access-denied']);
                        return false;
                    }

                }
            } else {
                return true;
            }


        }

        this.router.navigate(['/login']);
        return false;
    }
     getCookie(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(';');
      for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
      }
      return null;
    }
}
