import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { environment } from '@env/environment';
const httpOptions = {
  headers: new HttpHeaders({
    "ignoreLoadingBar": 'true'
  })
};
@Injectable({
  providedIn: 'root'
})
export class OpVisitService {

  constructor(private http: HttpClient) { }
  private extractData(res: Response) {
    let body = res;
    return body || { };
  }
  getOpDropdowns(): Observable<any> {
    return this.http.post(environment.API_ENDPOINT + 'OpVisitEntry/options',null).pipe(
      map(this.extractData));
  }
  getPatientDetails (postData): Observable<any> {
    return this.http.post<any>(environment.API_ENDPOINT + 'OpVisitEntry/getPatientDetails', JSON.stringify(postData)).pipe(
      tap((result) =>
      catchError(this.handleError<any>('getPatientDetails'))
    ));
  }

  getDrByDateDept (postData): Observable<any> {
    return this.http.post<any>(environment.API_ENDPOINT + 'OpVisitEntry/getDrByDateDept', JSON.stringify(postData)).pipe(
      tap((result) =>
      catchError(this.handleError<any>('getPatientDetails'))
    ));
  }

  getlocation (postData): Observable<any> {
    return this.http.post<any>(environment.API_ENDPOINT + 'OpVisitEntry/getlocation', JSON.stringify(postData)).pipe(
      tap((result) =>
      catchError(this.handleError<any>('getPatientDetails'))
    ));
  }
  gettype (postData): Observable<any> {
    return this.http.post<any>(environment.API_ENDPOINT + 'OpVisitEntry/gettype', JSON.stringify(postData)).pipe(
      tap((result) =>
      catchError(this.handleError<any>('getPatientDetails'))
    ));
  }
  getservice (postData): Observable<any> {
    return this.http.post<any>(environment.API_ENDPOINT + 'OpVisitEntry/getservice', JSON.stringify(postData)).pipe(
      tap((result) =>
      catchError(this.handleError<any>('getPatientDetails'))
    ));
  }

  getSocialStates (postData): Observable<any> {
    return this.http.post<any>(environment.API_ENDPOINT + 'OpVisitEntry/getSocialStates', JSON.stringify(postData)).pipe(
      tap((result) =>
        catchError(this.handleError<any>('getSocialStates'))
      ));
  }

  getsource (postData): Observable<any> {
    return this.http.post<any>(environment.API_ENDPOINT + 'OpVisitEntry/getsource', JSON.stringify(postData)).pipe(
      tap((result) =>
      catchError(this.handleError<any>('getPatientDetails'))
    ));
  }


  //getVisitListByDate
  getVisitListByDate (postData): Observable<any> {
    return this.http.post<any>(environment.API_ENDPOINT + 'OpVisitEntry/getVisitListByDate', JSON.stringify(postData),httpOptions).pipe(
      tap((result) =>
      catchError(this.handleError<any>('getPatientDetails'))
    ));
  }
  getCPTBySites (postData): Observable<any> {
    return this.http.post<any>(environment.API_ENDPOINT + 'OpVisitEntry/getCPTBySites', JSON.stringify(postData)).pipe(
      tap((result) =>
      catchError(this.handleError<any>('getPatientDetails'))
    ));
  }
  //updateInsuranceDetails
  updateInsuranceDetails (postData): Observable<any> {
     return this.http.post<any>(environment.API_ENDPOINT + 'OpVisitEntry/updateInsuranceDetails', JSON.stringify(postData)).pipe(
       tap((result) =>
       catchError(this.handleError<any>('updateInsuranceDetails'))
     ));
   }
   updateCompanyDetails(postData): Observable<any> {
    return this.http.post<any>(environment.API_ENDPOINT + 'OpVisitEntry/updateCompanyDetails', JSON.stringify(postData)).pipe(
      tap((result) =>
      catchError(this.handleError<any>('updateCompanyDetails'))
    ));
  }
   addVisit (postData): Observable<any> {
     return this.http.post<any>(environment.API_ENDPOINT + 'OpVisitEntry/addVisit', JSON.stringify(postData)).pipe(
       tap((result) =>
       catchError(this.handleError<any>('addVisit'))
     ));
     //
   }
  getReferralDoctorList(): Observable<any> {
    return this.http.post(environment.API_ENDPOINT + 'ReferralDoctor/getReferralDoctorList',null).pipe(
      tap((result) =>
        catchError(this.handleError<any>('getReferralDoctorList'))
      ));
  }

  getProceduresByVisitId(postData): Observable<any> {
    return this.http.post<any>(environment.API_ENDPOINT + 'OpVisitEntry/getProceduresByVisitId', JSON.stringify(postData)).pipe(
      tap((result) =>
        catchError(this.handleError<any>('getProceduresByVisitId'))
      ));
  }
  getReferrenceByVisitId(postData): Observable<any> {
    return this.http.post<any>(environment.API_ENDPOINT + 'OpVisitEntry/getReferrenceByVisitId', JSON.stringify(postData)).pipe(
      tap((result) =>
        catchError(this.handleError<any>('getReferrenceByVisitId'))
      ));
  }
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
