import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReceiptViewComponent } from './receipt-view/receipt-view.component';
import { NgxPrintModule } from 'ngx-print';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {FormsModule} from '@angular/forms';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { PageHeaderModule } from '@shared/modules/page-header/page-header.module';


@NgModule({
  declarations: [ReceiptViewComponent],
  imports: [
    CommonModule, PageHeaderModule, RouterModule, NgbModule, FormsModule, Ng2SearchPipeModule, NgxPrintModule
  ],
  exports: [ReceiptViewComponent]
})
export class ReceiptsViewModule { }
