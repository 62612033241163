import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReferralDoctorRoutingModule } from './referral-doctor-routing.module';
import { ReferralDoctorComponent } from './referral-doctor.component';
import { PageHeaderModule } from  '@shared/modules';
import { NgxLoadingComponent, NgxLoadingModule }  from 'ngx-loading';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { TextMaskModule } from "angular2-text-mask";

@NgModule({
  declarations: [ReferralDoctorComponent],
  imports: [
    CommonModule,
    ReferralDoctorRoutingModule,
    FormsModule,
    NgbModule,
    PageHeaderModule,
    NgxLoadingModule,
    NgbPaginationModule,
    TextMaskModule
  ]
})
export class ReferralDoctorModule { }
