import { Component, OnInit } from '@angular/core';
import {timeout} from "@env/environment";

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnInit {

  timeLeft: number = timeout.LOGOUT_T;
  interval
  minute =3
  second=0


  constructor() { }

  ngOnInit() {
    this.startTimer()
  }

  startTimerO() {
    this.interval = setInterval(() => {
      if(this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.timeLeft = 60;
      }
    },1000)
  }



  startTimer() {
    this.interval = setInterval(() => {
      let m = Math.floor(this.timeLeft / 60);
      let s = this.timeLeft % 60;
      // this.timeLeft -= 1;
      this.minute = m < 10 ? 0 + m : m;
      this.second = s < 10 ? 0 + s : s;
      if(this.timeLeft >= 0) {
        this.timeLeft--;
      }else{
        this.minute =0
        this.second =0
      }
    },1000)
  }

}
