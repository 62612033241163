import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { AppointmentListComponent } from './appointment-list/appointment-list.component';
import { OpVisitListComponent } from './op-visit-list/op-visit-list.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxPrintModule } from 'ngx-print';
import {Nl2BrPipeModule} from 'nl2br-pipe';
import { PageHeaderModule } from '@shared/modules/page-header/page-header.module';

@NgModule({
  declarations: [AppointmentListComponent,OpVisitListComponent],
  imports: [
    CommonModule,RouterModule,NgbModule,FormsModule,Ng2SearchPipeModule,NgxPrintModule,Nl2BrPipeModule,PageHeaderModule
  ],
  exports: [AppointmentListComponent,OpVisitListComponent]
})
export class DataListingModule {}
