import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmationComponent } from './confirmation.component';

@NgModule({
    declarations: [ConfirmationComponent],
    exports: [
        ConfirmationComponent
    ],
    imports: [
        CommonModule
    ]
})
export class ConfirmationModule { }
