import { NgModule,Input,ViewChild,Output } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NgxLoadingComponent } from 'ngx-loading';
import {ReferralDoctorComponent} from "@app/layout/master/referral-doctor/referral-doctor.component";

const routes: Routes = [
  {
    path:'', component:ReferralDoctorComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReferralDoctorRoutingModule { 
  @ViewChild('ngxLoading') ngxLoadingComponent: NgxLoadingComponent;
}
