import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { environment } from '@env/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};
var cpt_path = environment.API_ENDPOINT + 'Querys/getPatientList';
var patient_list_phone_path = environment.API_ENDPOINT + 'Querys/getPatientListWithPhone';
var ReportService = /** @class */ (function () {
    function ReportService(http) {
        this.http = http;
    }
    ReportService.prototype.extractData = function (res) {
        var body = res;
        return body || {};
    };
    ReportService.prototype.listCashReport = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Report/listCashReport', JSON.stringify(opData)).pipe(tap(function (result) { return catchError(_this.handleError('listCashReport')); }));
    };
    ReportService.prototype.listAllReport = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Report/listAllReport', JSON.stringify(opData)).pipe(tap(function (result) { return catchError(_this.handleError('listAllReport')); }));
    };
    ReportService.prototype.downloadmedicalPdf = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Report/downloadmedicalPdf', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('downloadmedicalPdf'));
        }));
    };
    ReportService.prototype.downloadmedicalPdf_visitdate = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Report/downloadmedicalPdf_visitdate', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('downloadmedicalPdf'));
        }));
    };
    ReportService.prototype.getVisitListByDate = function (postData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'OpVisitEntry/getVisitListByDate', JSON.stringify(postData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getPatientDetails'));
        }));
    };
    ReportService.prototype.getPatientList = function (term) {
        if (term === '') {
            return of([]);
        }
        return this.http
            .post(cpt_path, JSON.stringify({ search_text: term, limit: 50, start: 1 })).pipe(map(function (response) { return response['data']; }));
    };
    ReportService.prototype.getPatientListWithPhone = function (term) {
        if (term === '') {
            return of([]);
        }
        return this.http
            .post(patient_list_phone_path, JSON.stringify({ search_text: term, limit: 50, start: 1 })).pipe(map(function (response) { return response['data']; }));
    };
    /* getPatientList(postData): Observable<any> {
      return this.http.post(environment.API_ENDPOINT + 'Querys/getPatientList',JSON.stringify(postData)).pipe(
        map(this.extractData));
    }*/
    ReportService.prototype.listCreditReport = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Report/listCreditReport', JSON.stringify(opData)).pipe(tap(function (result) { return catchError(_this.handleError('listCreditReport')); }));
    };
    ReportService.prototype.getTPA = function () {
        return this.http.post(environment.API_ENDPOINT + 'OpRegistration/options', null).pipe(map(this.extractData));
    };
    ReportService.prototype.listDoctorList = function (postData) {
        return this.http.post(environment.API_ENDPOINT + 'Doctors/getDoctorList', JSON.stringify(postData)).pipe(map(this.extractData));
    };
    ReportService.prototype.getUserList = function () {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'UserManagement/getUserList', null).pipe(tap(function (result) { return catchError(_this.handleError('getUserList')); }));
    };
    ReportService.prototype.searchUserList = function () {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'UserManagement/searchUserList', null).pipe(tap(function (result) { return catchError(_this.handleError('searchUserList')); }));
    };
    ReportService.prototype.getVisitListByDateQuery = function (postData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Querys/getVisitListByDate', JSON.stringify(postData)).pipe(tap(function (result) { return catchError(_this.handleError('getVisitListByDateQuery')); }));
    };
    ReportService.prototype.listAllInvoices = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Report/listInvoices', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('listAllInvoices'));
        }));
    };
    ReportService.prototype.listDocuments = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Report/listDocuments', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('listDocuments'));
        }));
    };
    ReportService.prototype.saveCorporateCompany = function (postData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'CorporateCompany/saveCorporateCompany', JSON.stringify(postData)).pipe(tap(function (result) { return catchError(_this.handleError('saveCorporateCompany')); }));
    };
    ReportService.prototype.listCorporateCompany = function (postData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'CorporateCompany/listCorporateCompany', JSON.stringify(postData)).pipe(tap(function (result) { return catchError(_this.handleError('listCorporateCompany')); }));
    };
    ReportService.prototype.getCorporateCompany = function (postData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'CorporateCompany/getCorporateCompany', JSON.stringify(postData)).pipe(tap(function (result) { return catchError(_this.handleError('getCorporateCompany')); }));
    };
    ReportService.prototype.getBill = function (postData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Report/getBill', JSON.stringify(postData)).pipe(tap(function (result) { return catchError(_this.handleError('getBill')); }));
    };
    ReportService.prototype.saveDocuments = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'FileManage/saveFile', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('Document error'));
        }));
    };
    ReportService.prototype.listPatientVisits = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Report/listPatientVisits', JSON.stringify(opData)).pipe(tap(function (result) { return catchError(_this.handleError('listPatientVisits')); }));
    };
    ReportService.prototype.listProcedure = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Report/listProcedure', JSON.stringify(opData)).pipe(tap(function (result) { return catchError(_this.handleError('listProcedure')); }));
    };
    ReportService.prototype.handleError = function (operation, result) {
        if (operation === void 0) { operation = 'operation'; }
        return function (error) {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead
            // TODO: better job of transforming error for user consumption
            console.log(operation + " failed: " + error.message);
            // Let the app keep running by returning an empty result.
            return of(result);
        };
    };
    ReportService.ngInjectableDef = i0.defineInjectable({ factory: function ReportService_Factory() { return new ReportService(i0.inject(i1.HttpClient)); }, token: ReportService, providedIn: "root" });
    return ReportService;
}());
export { ReportService };
