import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, enableProdMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {NgxLoadingComponent, NgxLoadingModule, ngxLoadingAnimationTypes,NgxLoadingService }  from 'ngx-loading';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {AuthGuard, TimerModule} from '@shared/index';;
import { BasicAuthInterceptor } from './shared/helper';
import { UserIdleModule } from 'angular-user-idle';
import {  AppointmentService, OpVisitService, DrConsultationService, OpRegistrationService, PatientQueryService } from '@shared/index';;
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { LayoutComponent } from './layout/layout.component';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import {ThemeService} from 'ng2-charts';
// import { NgcFloatButtonModule} from 'ngc-float-button';
//import {NgxSpinnerService,NgxSpinnerModule} from 'ngx-spinner';
// import { SharedComponent } from './shared/shared.component';
// import { ModulesComponent } from './shared/modules/modules.component';

import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';

// for Router import:
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';

// for Core import:
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { ReferralDoctorModule } from './layout/master/referral-doctor/referral-doctor.module';
// import { MedicalReportComponent } from './shared/modules/medical-report/medical-report.component';
// AoT requires an exported function for factories

import { timeout} from "@env/environment";

export const createTranslateLoader = (http: HttpClient) => {
    /* for development
    return new TranslateHttpLoader(
        http,
        '/start-angular/SB-Admin-BS4-Angular-6/master/dist/assets/i18n/',
        '.json'
    ); */
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};
const customNotifierOptions: NotifierOptions = {
    position: {
          horizontal: {
              position: 'middle',
              distance: 12
          },
          vertical: {
              position: 'top',
              distance: 12,
              gap: 10
          }
      },
    theme: 'material',
    behaviour: {
      autoHide: 3000,
      onClick: 'hide',
      onMouseover: 'pauseAutoHide',
      showDismissButton: true,
      stacking: 4
    },
    animations: {
      enabled: true,
      show: {
        preset: 'slide',
        speed: 300,
        easing: 'ease'
      },
      hide: {
        preset: 'fade',
        speed: 300,
        easing: 'ease',
        offset: 50
      },
      shift: {
        speed: 300,
        easing: 'ease'
      },
      overlap: 150
    }
  };
const customLoaderOptions = {
    animationType: ngxLoadingAnimationTypes.wanderingCubes,
    backdropBackgroundColour: 'rgba(0,0,0,0.7)',
    backdropBorderRadius: '4px',
    primaryColour: '#dd0031',
    secondaryColour: '#006ddd',
    tertiaryColour: '#fff',
    fullScreenBackdrop:true
};
enableProdMode();
@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    UserIdleModule.forRoot({idle: timeout.IDLE, timeout: timeout.LOGOUT, ping: timeout.WARNING}),
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    NotifierModule.withConfig(customNotifierOptions),
    NgxLoadingModule.forRoot(customLoaderOptions),
    AppRoutingModule, SelectDropDownModule,
    // for HttpClient use:
    LoadingBarHttpClientModule,

    // for Router use:
    LoadingBarRouterModule,

    // for Core use:
    LoadingBarModule,

    ReferralDoctorModule, TimerModule,

  ],
    declarations: [AppComponent],
    providers: [HttpClient, AuthGuard, {
        provide: HTTP_INTERCEPTORS,
        useClass: BasicAuthInterceptor,
        multi: true
    }, AppointmentService, OpVisitService, DrConsultationService, OpRegistrationService, PatientQueryService, LayoutComponent, NgxLoadingService, ThemeService],
    exports: [

    ],

    bootstrap: [AppComponent]
})
export class AppModule {}
