<app-page-header [heading]="'Referral Doctor'" [icon]="'fa fa-user-md'"></app-page-header>
  <fieldset class="form-group row" >
   <legend>New</legend>
   <div class="row col-lg-12">
    <div class="form-group col-lg-5" >
        <label>Doctor Name</label><span class="text-danger">&nbsp;*</span>
        <input [(ngModel)]="referraldoctor_data.referraldoctor_name" class="form-control form-control-sm" type="text" name="referraldoctor_name"   id="referraldoctor_name">
    </div>
    <div class="form-group col-lg-5" >
      <label>Clinician id</label><span class="text-danger">&nbsp;*</span>
      <input [(ngModel)]="referraldoctor_data.refferraldoctor_clinitian_id" [textMask]="{mask:licence_mask}" placeholder="DHA-#-00000000" class="form-control form-control-sm" type="text" name="referraldoctor_clinitian"   id="referraldoctor_clinitian">

  </div>
  <div class="form-group col-lg-2">
    <label>Status</label><br>
    <label class="radio-inline">
      <input  type="radio" name="status" id="active" value="1" (click)="selectStatus(1)" [checked]="referraldoctor_data.referraldoctor_stat == 1"   name="status" id="active" value="1"  >&nbsp;&nbsp;Active &nbsp;&nbsp;
    </label>
    <label class="radio-inline">
      <input  type="radio" name="status" id="in-active" value="0" (click)="selectStatus(2)" [checked]="referraldoctor_data.referraldoctor_stat == 2" name="status" id="in-active" value="2" >&nbsp;&nbsp;Inactive
    </label>
  </div>


  </div>

  <div class="clearfix"></div>
  <div class="clearfix"></div>
  <div class="form-group col-lg-7 pull-right">
    <button  class="btn btn-primary btn-sm ng-star-inserted" type="button" (click)="saveReferral();" >&nbsp;Submit&nbsp;</button>&nbsp;&nbsp;&nbsp;
    <button  class="btn btn-danger btn-sm ng-star-inserted" type="button" (click)="clear();" >&nbsp;Clear&nbsp; </button>
  </div>
  <div class="clearfix"></div>
</fieldset>



<fieldset class="form-group row">
  <legend>List</legend>
  <div class="col-lg-12 ">
      <table class="col-lg-12 borderless">
        <tr>
          <td width="40%" class="text-left">
              <div class="input-group mb-3 pull-left">
                  <input id="typeahead-http" style="height:31px" type="text" class="form-control"
                  [(ngModel)]="search_ref"
                  (keyup)=" getReferralDoctorList($event,1)"
                  (keypress)=" getReferralDoctorList($event,1)"
                  (paste)= " getReferralDoctorList($event,1)"
                         name="search_text" placeholder="Doctor Name  / id ">
                  <div class="input-group-append">
                      <button style="height:31px;padding:7px" class="input-group-text" (click)="clear_search()">
                          <!-- <i    class="fa fa-close"></i> -->
                          <i  class="fa fa-search"></i>
                      </button>
                  </div>
              </div>
            <div class="invalid-feedback">Sorry, suggestions could not be loaded.</div>
          </td>
          <td width="50%" class="text-right" style="vertical-align: top;"></td>
        </tr>
      </table>
  </div>
  <div class="col-lg-12 ">
      <table class="table tbl table-hover table-striped table-bordered">
          <thead >
          <tr>
              <th  width="2%" class="text-center">#</th>
              <th class="text-center" width="30%">Dr.Name</th>
              <th class="text-center" width="10%">Clinician id </th>
              <th  class="text-center" width="7%">Status</th>
              <th  class="text-center" width="2%">Action</th>
          </tr>

          <tr *ngIf="(referraldoctor_list| json) == '[]'">
            <!-- <td colspan="8" class="text-center">No details available</td> -->
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let ref of referraldoctor_list;let i=index">
            <td>{{start + i + 1 }}</td>
            <td>{{ref.REFERRAL_DOCTOR_NAME}}</td>
            <td>{{ref.REFERRAL_DOCTOR_CLINITIAN_ID}}</td>
            <td>
              <ng-container *ngIf="ref.REFERRAL_DOCTOR_STATUS ==1">Active</ng-container>
             <ng-container *ngIf="ref.REFERRAL_DOCTOR_STATUS !=1">Inactive</ng-container>
            </td>
            <td>
              <button   (click)="editReferralDoctor(ref);" alt="Edit" title="Edit" class="btn btn-sm btn-default"><i class="fa fa-edit"></i></button>
            </td>
          </tbody>
      </table>
    <ngb-pagination
      [disabled]="''"
      class="d-flex justify-content-center"
      [(page)]="page"
      [pageSize]="p"
      [collectionSize]="collection"
      [directionLinks]="true"
      [boundaryLinks]="true"
      [(page)]="page" aria-label="Default pagination"
      [maxSize]="3"
      (pageChange)="getReferralDoctorList(page-1)">
    </ngb-pagination>
      <pre>Current page : {{page}}</pre>
      <pre>Total records : {{collection}}</pre>
  </div>
</fieldset>
