import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UserIdleService} from "angular-user-idle";
import {ModalDismissReasons, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import { Router } from '@angular/router';
import { FormsModule } from "@angular/forms";
import * as moment from "moment/moment";


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    public loading : boolean;
  @ViewChild('timeUp') popup
  @ViewChild('timer') Timer! : ElementRef
  closeResult: string;
  timing
  timeUp=false;
  sessionOut=0;
  idle=0

  constructor( private userIdle: UserIdleService,
               private modal: NgbModal,
               public router: Router,
               public eRef: ElementRef
    ) {}

    ngOnInit() {
      localStorage.setItem('logStat', '0');
      // this.userIdle.stopTimer();
      // this.userIdle.stopWatching()
      this.findIdle()
    }
    public findIdle(){
      this.userIdle.startWatching();
      this.userIdle.onTimerStart().subscribe(()=>{
        let popupFlag=0
        if(localStorage.getItem('logStat')=='initial' && this.idle==1) {
        // if(localStorage.getItem('logStat')=='initial') {
          popupFlag=1
          this.sessionOut=0
          this.modal.open(this.popup,
            {
              ariaLabelledBy: 'modal-basic-title',
              windowClass: "col-md-12",
              backdrop : 'static',
              keyboard : false
            }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
          }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          });
          localStorage.setItem('logStat', 'updated');
        }
      });
      this.userIdle.ping$.subscribe(() => { // Start watch when time is up.
        if (localStorage.getItem('logStat')=='initial'){
            this.idle=1
        }
      });
      // Calls on time-out
      this.userIdle.onTimeout().subscribe(() => {
        this.logOffUser(2)
        this.sessionOut=1
        this.idle=0
      });
    }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }
  public logOffUser(p=0){
    if (p==1){
      this.modal.dismissAll();
    }
    // this.userIdle.resetTimer();
    // this.userIdle.stopWatching()
    // this.userIdle.stopTimer();
    localStorage.setItem('logStat', '0');
    let key = localStorage.getItem('tokey')?localStorage.getItem('tokey'):''
    if(key !='') {
      this.eraseCookie('key')
    }
    localStorage.removeItem('isLoggedin');
    localStorage.removeItem('activeTime');
    localStorage.removeItem('user');
    localStorage.removeItem('modules');
    localStorage.removeItem('sub_modules');
    localStorage.removeItem('access_types');
    localStorage.removeItem('user_rights');
    localStorage.removeItem('institution');
    localStorage.removeItem('logo_path');
    localStorage.removeItem('doctor_department');
    this.router.navigate(['login']);
    this.sessionOut=0
    this.idle=0
    this.userIdle.stopTimer()

  }
  public reLogOn(){
    this.modal.dismissAll()
    this.userIdle.resetTimer()
    localStorage.setItem('logStat', 'initial')
    this.idle=0
  }

  eraseCookie(name) {
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }
  reLoad(){
    window.location.reload();
    this.userIdle.stopWatching()
    this.userIdle.stopTimer();
  }

}
