import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PasswordService {

   numCheck =/\d/
   smAlphCheck =/[a-z]/
   capAlphCheck =/[A-Z]/
   spCharCheck =/[!”#$%&()*+,-./:;<=>?@[\]^_`{|}~]/
   testFlag = 0;
  constructor() { }

  validatePassword(password){
    console.log("password - ",password)
    if (this.numCheck.test(password)==false){
      return {status:'error',msg:'Passwords must have at least one numeric character'}
    }else if (this.smAlphCheck.test(password)==false){
      return {status:'error',msg:'Passwords must have at least one lowercase alphabetical character'}
    }else if (this.capAlphCheck.test(password)==false){
      return {status:'error',msg:'Passwords must have at least one uppercase alphabetical character'}
    }else if (this.spCharCheck.test(password)==false){
      return {status:'error',msg:'Passwords must have at least one special character'}
    }else if (password.trim().length<8) {
      return {status:'error',msg:'Passwords must have minimum 8 characters'}
    }else {
      return {status:'success',msg:''}
    }
  }
}
