import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '@env/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};
var PARAMS = new HttpParams({
    fromObject: {
        action: 'opensearch',
        format: 'json',
        origin: '*'
    }
});
var ReferralDoctorService = /** @class */ (function () {
    function ReferralDoctorService(http) {
        this.http = http;
    }
    ReferralDoctorService.prototype.listReferralDoctor = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'ReferralDoctor/referalDoctorList', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('listReferralDoctor'));
        }));
    };
    ReferralDoctorService.prototype.getReferralDoctor = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'ReferralDoctor/getReferralDoctor', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getReferralDoctor'));
        }));
    };
    ReferralDoctorService.prototype.saveReferral = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'ReferralDoctor/saveReferralDoctor', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('saveReferral'));
        }));
    };
    ReferralDoctorService.prototype.handleError = function (operation, result) {
        if (operation === void 0) { operation = 'operation'; }
        return function (error) {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead
            // TODO: better job of transforming error for user consumption
            console.log(operation + " failed: " + error.message);
            // Let the app keep running by returning an empty result.
            return of(result);
        };
    };
    ReferralDoctorService.ngInjectableDef = i0.defineInjectable({ factory: function ReferralDoctorService_Factory() { return new ReferralDoctorService(i0.inject(i1.HttpClient)); }, token: ReferralDoctorService, providedIn: "root" });
    return ReferralDoctorService;
}());
export { ReferralDoctorService };
