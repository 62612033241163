import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { environment } from '@env/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};
var cpt_path1 = environment.API_ENDPOINT + 'Querys/getPatientList';
// const WIKI_URL = 'https://en.wikipedia.org/w/api.php';
var cpt_path = environment.API_ENDPOINT + 'CurrentProceduralCode/listCurrentProceduralCodeforTreatment';
var diagnosis_path = environment.API_ENDPOINT + 'Diagnosis/listDiagnosis';
var medicine_path = environment.API_ENDPOINT + 'Medicine/listMedicine';
var generic_path = environment.API_ENDPOINT + 'Medicine/listMedicine';
var vaccine_path = environment.API_ENDPOINT + 'Immunization/listImmunization';
var routeofadmin_path = environment.API_ENDPOINT + '/DoctorPrescription/listRouteOfAdmin';
var PARAMS = new HttpParams({
    fromObject: {
        action: 'opensearch',
        format: 'json',
        origin: '*'
    }
});
var ConsultingService = /** @class */ (function () {
    function ConsultingService(http) {
        this.http = http;
    }
    /*search(term: string) {
      if (term === '') {
        return of([]);
      }
  
      return this.http
        .get(WIKI_URL, {params: PARAMS.set('search', term)}).pipe(
          map(response => response[1])
        );
    }*/
    ConsultingService.prototype.cptsearch = function (term) {
        if (term === '') {
            return of([]);
        }
        return this.http
            .post(cpt_path, JSON.stringify({ search_text: term, limit: 50, current_procedural_code_id: '0', start: 1 })).pipe(map(function (response) { return response['data']; }));
    };
    ConsultingService.prototype.cptDentalsearch = function (term, procedure_code_category, dental_procedure_id) {
        if (term === '') {
            return of([]);
        }
        return this.http
            .post(cpt_path, JSON.stringify({
            search_text: term, limit: 50,
            current_procedural_code_id: '0',
            start: 1,
            procedure_code_category: procedure_code_category,
            dental_procedure_id: dental_procedure_id
        })).pipe(map(function (response) { return response['data']; }));
    };
    ConsultingService.prototype.master_cptsearch = function (term) {
        if (term === '') {
            return of([]);
        }
        return this.http
            .post(cpt_path, JSON.stringify({ search_text: term, limit: 50, current_procedural_code_id: '0', start: 0 })).pipe(map(function (response) { return response['data']; }));
    };
    ConsultingService.prototype.diagnosis_search = function (data) {
        if (data.term === '') {
            return of([]);
        }
        var doc_stat = data.doc_stat == 1 ? data.doc_stat : 0;
        return this.http
            .post(diagnosis_path, JSON.stringify({ search_text: data.term, limit: 50, diagnosis_id: '0', start: 0, doc_stat: doc_stat })).pipe(map(function (response) { return response['data']; }));
    };
    ConsultingService.prototype.listCpt = function (postdata) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'CurrentProceduralCode/listCurrentProceduralCode', JSON.stringify(postdata)).pipe(tap(function (result) {
            return catchError(_this.handleError('getcpt'));
        }));
    };
    ConsultingService.prototype.medicine_search = function (term) {
        if (term === '') {
            return of([]);
        }
        return this.http
            .post(medicine_path, JSON.stringify({ search_text: term, limit: 50, medicine_id: '0', start: 0 })).pipe(map(function (response) { return response['data']; }));
    };
    ConsultingService.prototype.routeofadmin_search = function (term) {
        if (term === '') {
            return of([]);
        }
        return this.http
            .post(routeofadmin_path, JSON.stringify({ search_text: term, limit: 50, routeofadmin_id: '0', start: 0 })).pipe(map(function (response) { return response['data']; }));
    };
    ConsultingService.prototype.generic_search = function (term) {
        if (term === '') {
            return of([]);
        }
        return this.http
            .post(generic_path, JSON.stringify({ search_text: term, limit: 50, medicine_id: '0', start: 0, is_doc: 1 })).pipe(map(function (response) { return response['data']; }));
    };
    ConsultingService.prototype.vaccine_search = function (term) {
        if (term === '') {
            return of([]);
        }
        return this.http
            .post(vaccine_path, JSON.stringify({ search_text: term, limit: 50, immunization_id: '0', start: 0 })).pipe(map(function (response) { return response['data']; }));
    };
    ConsultingService.prototype.extractData = function (res) {
        var body = res;
        return body || {};
    };
    ConsultingService.prototype.saveInsurancePrice = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'InsurancePrice/saveInsurancePrice', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('saveInsurancePrice'));
        }));
    };
    ConsultingService.prototype.getInsurancePrice = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'InsurancePrice/getInsurancePrice', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getInsurancePrice'));
        }));
    };
    ConsultingService.prototype.listInsurancePrice = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'InsurancePrice/listInsurancePrice', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('listInsurancePrice'));
        }));
    };
    ConsultingService.prototype.save_specialComments = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + '/PatientSpecialComment/savePatientSpecialComment', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('savecomplaints'));
        }));
    };
    ConsultingService.prototype.getSpecialcomments = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'PatientSpecialComment/getPatientSpecialComment', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('Document error'));
        }));
    };
    ConsultingService.prototype.getPreviousSpecialcomments = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'PatientSpecialComment/getPreviousPatientSpecialComment', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('Document error'));
        }));
    };
    ConsultingService.prototype.saveComplaints = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'ChiefComplaints/saveComplaints', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('savecomplaints'));
        }));
    };
    ConsultingService.prototype.saveDentalComplaints = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'DentalComplaints/saveDentalComplaints', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('saveDentalComplaints'));
        }));
    };
    ConsultingService.prototype.saveDentalHistory = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'DentalComplaints/saveDentalHistory', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('saveDentalHistory'));
        }));
    };
    ConsultingService.prototype.saveTpa = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'TPA_reciever/saveTPA', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('savetpa'));
        }));
    };
    ConsultingService.prototype.saveType = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Laboratory/saveType', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('saveType'));
        }));
    };
    ConsultingService.prototype.getTypelist = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Laboratory/listType', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getTypelist error'));
        }));
    };
    ConsultingService.prototype.getType = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Laboratory/getType', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getType error'));
        }));
    };
    ConsultingService.prototype.saveTest = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Laboratory/saveTest', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('saveTest'));
        }));
    };
    ConsultingService.prototype.getTestlist = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Laboratory/listTest', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getTestlist error'));
        }));
    };
    ConsultingService.prototype.getTest = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Laboratory/getTest', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getTest error'));
        }));
    };
    ConsultingService.prototype.saveCollection = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Laboratory/saveCollection', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('saveCollection'));
        }));
    };
    ConsultingService.prototype.attachcollection = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Laboratory/attachcollection', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('attachcollection'));
        }));
    };
    ConsultingService.prototype.attachradiology = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Laboratory/attachradiology', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('attachradiology'));
        }));
    };
    ConsultingService.prototype.getCollectionlist = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Laboratory/listCollection', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getCollectionlist error'));
        }));
    };
    ConsultingService.prototype.getStickerData = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Laboratory/getStickerData', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getStickerData'));
        }));
    };
    ConsultingService.prototype.changeStatus = function (params) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Laboratory/changeStatus', JSON.stringify(params)).pipe(tap(function (result) {
            return catchError(_this.handleError('changeStatus'));
        }));
    };
    ConsultingService.prototype.removefile = function (params) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Laboratory/removefile', JSON.stringify(params)).pipe(tap(function (result) {
            return catchError(_this.handleError('removefile'));
        }));
    };
    ConsultingService.prototype.removeradiofile = function (params) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Laboratory/removeradiofile', JSON.stringify(params)).pipe(tap(function (result) {
            return catchError(_this.handleError('removeradiofile'));
        }));
    };
    ConsultingService.prototype.searchCollection = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Laboratory/searchCollection', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('searchCollection error'));
        }));
    };
    ConsultingService.prototype.searchradiology = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Laboratory/searchradiology', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('searchradiology error'));
        }));
    };
    ConsultingService.prototype.getattachradio = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Laboratory/getattachradio', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getattachradio'));
        }));
    };
    ConsultingService.prototype.getOpDropdowns = function () {
        return this.http.post(environment.API_ENDPOINT + 'OpRegistration/options', null).pipe(map(this.extractData));
    };
    ConsultingService.prototype.getPatientList = function (term) {
        if (term === '') {
            return of([]);
        }
        return this.http
            .post(cpt_path1, JSON.stringify({ search_text: term, limit: 50, start: 1 })).pipe(map(function (response) { return response['data']; }));
    };
    ConsultingService.prototype.getDropdowns = function () {
        return this.http.post(environment.API_ENDPOINT + 'Appointment/options', null).pipe(map(this.extractData));
    };
    ConsultingService.prototype.getDoctors = function () {
        return this.http.post(environment.API_ENDPOINT + 'Laboratory/getDoctors', null).pipe(map(this.extractData));
    };
    ConsultingService.prototype.getlab = function (opData) {
        return this.http.post(environment.API_ENDPOINT + 'Laboratory/getlab', JSON.stringify(opData)).pipe(map(this.extractData));
    };
    // getlabData(opData): Observable<any> {
    //   return this.http.post(environment.API_ENDPOINT + 'Laboratory/getlabData',JSON.stringify(opData)).pipe(
    //     map(this.getlabData));
    // }
    ConsultingService.prototype.getlabData = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Laboratory/getlabData', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getlabData'));
        }));
    };
    ConsultingService.prototype.cancelSample = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Laboratory/cancelSample', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('cancelSample'));
        }));
    };
    ConsultingService.prototype.getdroptest = function () {
        return this.http.post(environment.API_ENDPOINT + 'Laboratory/testoptions', null).pipe(map(this.extractData));
    };
    ConsultingService.prototype.getCollection = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Laboratory/getCollection', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getCollection error'));
        }));
    };
    ConsultingService.prototype.saveInsurancepayer = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Insurance_payer/saveinsurance_payers', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('saveInsurancepayer'));
        }));
    };
    ConsultingService.prototype.saveNetwork = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'INS_network/saveinsnetwork', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('saveNetwork'));
        }));
    };
    ConsultingService.prototype.getPreviousComplaints = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'ChiefComplaints/getPreviousComplaints', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('Document error'));
        }));
    };
    ConsultingService.prototype.getTpalist = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'TPA_reciever/listTPA', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getTpalist error'));
        }));
    };
    ConsultingService.prototype.getInspayerlist = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Insurance_payer/listinsurance_payers', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getInspayerlist error'));
        }));
    };
    ConsultingService.prototype.getNetworklist = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'INS_network/listinsnetwork', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getNetworklist error'));
        }));
    };
    ConsultingService.prototype.getTpa = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'TPA_reciever/getTPA', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getTpa error'));
        }));
    };
    ConsultingService.prototype.getInscompany = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Insurance_payer/getinsurance_payers', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getInscompany error'));
        }));
    };
    ConsultingService.prototype.getNetwork = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'INS_network/getinsnetwork', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getNetwork error'));
        }));
    };
    ConsultingService.prototype.getComplaints = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'ChiefComplaints/getComplaints', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('Document error'));
        }));
    };
    ConsultingService.prototype.getDentalComplaints = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'DentalComplaints/getDentalComplaints', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getDentalComplaints'));
        }));
    };
    ConsultingService.prototype.getDentalHistory = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'DentalComplaints/getDentalHistory', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getDentalHistory'));
        }));
    };
    ConsultingService.prototype.listNotAllowedProcedure = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'DentalComplaints/listNotAllowedProcedure', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('listNotAllowedProcedure'));
        }));
    };
    ConsultingService.prototype.listDentalProcedure = function () {
        return this.http.post(environment.API_ENDPOINT + 'DentalComplaints/listDentalProcedure', null).pipe(map(this.extractData));
    };
    // downloadFile(): any {
    // 	return this.http.get(environment.API_ENDPOINT + 'public/uploads/pdf_name.pdf', {responseType: 'blob'});
    // }
    ConsultingService.prototype.downloadDentalHistoryPdf = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'DentalComplaints/downloadDentalHistoryPdf', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('downloadDentalHistoryPdf'));
        }));
    };
    ConsultingService.prototype.get_master_data = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Settings/get_master_data', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('get_master_data'));
        }));
    };
    ConsultingService.prototype.get_filetype = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Settings/get_master_data', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError(' get_filetype'));
        }));
    };
    ConsultingService.prototype.get_Documenttype = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Settings/get_master_data', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError(' get_Documenttype'));
        }));
    };
    ConsultingService.prototype.get_priority = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Settings/get_master_data', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError(' get_filetype'));
        }));
    };
    ConsultingService.prototype.savePatientDiagnosis = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'PatientDiagnosis/savePatientDiagnosis', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('savePatientDiagnosis'));
        }));
    };
    ConsultingService.prototype.savePatientChronicDiagnosis = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'PatientDiagnosis/savePatientChronicDiagnosis', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('savePatientChronicDiagnosis'));
        }));
    };
    ConsultingService.prototype.getPatientDiagnosis = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'PatientDiagnosis/getPatientDiagnosis', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getPatientDiagnosis'));
        }));
    };
    ConsultingService.prototype.getPatientChronicDiagnosis = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'PatientDiagnosis/getPatientChronicDiagnosis', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getPatientDiagnosis'));
        }));
    };
    ConsultingService.prototype.deletePatientDiagnosisDetails = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'PatientDiagnosis/deletePatientDiagnosisDetails', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('deletePatientDiagnosisDetails'));
        }));
    };
    ConsultingService.prototype.deleteChronicDiagnosis = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'PatientDiagnosis/deleteChronicDiagnosis', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('deleteChronicDiagnosis'));
        }));
    };
    ConsultingService.prototype.getPreviousDiagnosis = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'PatientDiagnosis/getPreviousDiagnosis', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getPreviousDiagnosis'));
        }));
    };
    ConsultingService.prototype.listDiagnosis = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Diagnosis/listDiagnosis', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('listDiagnosis'));
        }));
    };
    ConsultingService.prototype.getDiagnosis = function (postdata) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Diagnosis/getDiagnosis', JSON.stringify(postdata)).pipe(tap(function (result) {
            return catchError(_this.handleError('listDiagnosis'));
        }));
    };
    ConsultingService.prototype.saveReportsAndNotes = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'ReportsAndNotes/saveReportsAndNotes', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('saveReportsAndNotes'));
        }));
    };
    ConsultingService.prototype.getReportsAndNotes = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'ReportsAndNotes/getReportsAndNotes', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getReportsAndNotes'));
        }));
    };
    ConsultingService.prototype.listImmunization = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Immunization/listImmunization', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('listImmunization'));
        }));
    };
    ConsultingService.prototype.getImmunization = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Immunization/getImmunization', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getImmunization'));
        }));
    };
    ConsultingService.prototype.saveImmunization = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Immunization/saveImmunization', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('saveImmunization'));
        }));
    };
    ConsultingService.prototype.savePatientImmunization = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'PatientImmunization/savePatientImmunization', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('savePatientImmunization'));
        }));
    };
    ConsultingService.prototype.getPatientImmunization = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'PatientImmunization/getPatientImmunization', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getPatientImmunization'));
        }));
    };
    ConsultingService.prototype.listMedicine = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Medicine/listMedicine', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('listMedicine'));
        }));
    };
    ConsultingService.prototype.listRouteOfAdmin = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'DoctorPrescription/listRouteOfAdmin', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('listRouteOfAdmin'));
        }));
    };
    ConsultingService.prototype.getRouteOfAdmin = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'DoctorPrescription/getRouteOfAdmin', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getRouteOfAdmin'));
        }));
    };
    ConsultingService.prototype.getMedicine = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Medicine/getMedicine', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getMedicine'));
        }));
    };
    ConsultingService.prototype.listGenericType = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'MedicineGenericType/listGenericType', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('listGenericType'));
        }));
    };
    ConsultingService.prototype.getGenericType = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'MedicineGenericType/getGenericType', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getGenericType'));
        }));
    };
    ConsultingService.prototype.getPrescription = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'DoctorPrescription/getPrescription', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getPrescription'));
        }));
    };
    ConsultingService.prototype.deletePrescriptionDetails = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'DoctorPrescription/deletePrescriptionDetails', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('deletePrescriptionDetails'));
        }));
    };
    ConsultingService.prototype.savePrescription = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'DoctorPrescription/savePrescription', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('savePrescription'));
        }));
    };
    ConsultingService.prototype.uploadToeRx = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'DoctorPrescription/generateRxFile', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('generateRxFile'));
        }));
    };
    ConsultingService.prototype.savePatientSickLeave = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'PatientSickLeave/savePatientSickLeave', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('savePatientSickLeave'));
        }));
    };
    ConsultingService.prototype.downloaSickleavePdf = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'PatientSickLeave/downloaSickleavePdf', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('downloaSickleavePdf'));
        }));
    };
    ConsultingService.prototype.getPatientSickLeave = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'PatientSickLeave/getPatientSickLeave', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getPatientSickLeave'));
        }));
    };
    ConsultingService.prototype.listPatientSickLeave = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'PatientSickLeave/listPatientSickLeave', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('listPatientSickLeave'));
        }));
    };
    ConsultingService.prototype.getPreviousPrescription = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'DoctorPrescription/getPreviousPrescription', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getPreviousPrescription'));
        }));
    };
    ConsultingService.prototype.getCancelPrescription = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'DoctorPrescription/getCancelPrescription', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getCancelPrescription'));
        }));
    };
    ConsultingService.prototype.getDrugTming = function () {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'DoctorPrescription/getDrugTming', null).pipe(tap(function (result) {
            return catchError(_this.handleError('getDrugTming'));
        }));
    };
    ConsultingService.prototype.getCDTByProcedureId = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'DentalComplaints/getCDTByProcedureId', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getCDTByProcedureId'));
        }));
    };
    ConsultingService.prototype.generateRadiologyXmlData = function (postData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'LabInvestigation/generateRadiologyXmlData', JSON.stringify(postData)).pipe(tap(function (result) {
            return catchError(_this.handleError('generateRadiologyXmlData'));
        }));
    };
    ConsultingService.prototype.getdentalProcedure = function () {
        return this.http.post(environment.API_ENDPOINT + 'CurrentProceduralCode/options', null).pipe(map(this.extractData));
    };
    //save Doctor status
    ConsultingService.prototype.doctorAssessmentStarted = function (data) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Consultation/doctorAssessmentStarted', JSON.stringify(data)).pipe(tap(function (result) {
            return catchError(_this.handleError('doctorAssessmentStarted'));
        }));
    };
    ConsultingService.prototype.chiefComplaintsCommonSearch = function (data) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'RepeatedEntry/chiefComplaintsCommonSearch', JSON.stringify(data)).pipe(tap(function (result) {
            return catchError(_this.handleError('chiefComplaintsCommonSearch'));
        }));
    };
    ConsultingService.prototype.saveDentalPlans = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'DentalComplaints/saveDentalPlans', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('saveDentalPlans'));
        }));
    };
    // /*****************************************************************
    //  * For list Dental Plans
    //  *13/10/2022
    //  *Gokul
    //  ******************************************************************/
    ConsultingService.prototype.listDentalPlans = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'DentalComplaints/listDentalPlans', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('listNotAllowedProcedure'));
        }));
    };
    // /*****************************************************************
    //  * For get Dental Plans
    //  *13/10/2022
    //  *Gokul
    //  ******************************************************************/
    ConsultingService.prototype.getDentalPlans = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'DentalComplaints/getDentalPlans', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getDentalPlans'));
        }));
    };
    ConsultingService.prototype.getDentalPlansWithDetails = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'DentalComplaints/getDentalPlansWithDetails', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getDentalPlansWithDetails'));
        }));
    };
    ConsultingService.prototype.completeDentalPlanProcedure = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'DentalComplaints/completeDentalPlanProcedure', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('completeDentalPlanProcedure'));
        }));
    };
    // /*****************************************************************
    //  * For get Dental Plans
    //  *13/10/2022
    //  *Gokul
    //  ******************************************************************/
    ConsultingService.prototype.listMasterTreatments = function () {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'DentalComplaints/listMasterTreatments', null).pipe(tap(function (result) {
            return catchError(_this.handleError('listMasterTreatments'));
        }));
    };
    // To handle error
    ConsultingService.prototype.handleError = function (operation, result) {
        if (operation === void 0) { operation = 'operation'; }
        return function (error) {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead
            // TODO: better job of transforming error for user consumption
            console.log(operation + " failed: " + error.message);
            // Let the app keep running by returning an empty result.
            return of(result);
        };
    };
    ConsultingService.ngInjectableDef = i0.defineInjectable({ factory: function ConsultingService_Factory() { return new ConsultingService(i0.inject(i1.HttpClient)); }, token: ConsultingService, providedIn: "root" });
    return ConsultingService;
}());
export { ConsultingService };
