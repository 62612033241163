import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { environment } from '@env/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var httpOptions = {
    headers: new HttpHeaders({
        "ignoreLoadingBar": 'true'
    })
};
var ApiService = /** @class */ (function () {
    function ApiService(http) {
        this.http = http;
        this.FileManage = {
            otherDocuments: "FileManage/listOtherDocuments"
        };
        this.Settings = {
            masterData: "Settings/get_master_data"
        };
        this.ConsentForms = {
            departments: "Forms/listDepartments",
            saveForm: "Forms/saveForm",
            listForms: "Forms/listForms",
            cpts: "CurrentProceduralCode/listCurrentProceduralCodeforTreatment"
        };
        this.OpVisit = {
            VisitStates: "OpVisitEntry/getPatientVisitStates",
            formData: "OpVisitEntry/getPatientConsentFormById",
            unsettledBillCheck: "NewBill/checkUnsettledBill",
            depatmentDoctors: "OpVisitEntry/getDrByDateDept"
        };
        this.Doctor = {
            DoctorsDepartment: "Doctors/getDoctorDepartment",
            PatientConsent: "Doctors/getConsents",
            DoctorCategory: "Doctors/getDoctorCategory",
            DeleteProcedure: "Consultation/deleteCompletedProcedure",
            getPatientEmrStat: "PatientDiagnosis/getPatientEmrStat"
        };
        this.Medicine = {
            routeOfAdmin: "Medicine/getRouteOfAdmin",
            getMedicine: "Medicine/getMedicine"
        };
        this.visitStatus = {
            listVisitStatus: 'Department/listVisitStatus',
            saveVisitStatus: 'Department/saveVisitStatus',
            departments: 'Department/listDepartment',
        };
        this.Dental = {
            getToothStructure: 'DentalComplaints/getToothStructure',
            getPlanToothStructure: 'DentalComplaints/getPlanToothStructure',
            getAllDentalHistory: 'DentalComplaints/getAllDentalHistory',
            deleteDentalProcedure: 'DentalComplaints/deleteDentalInvestigation'
        };
        this.ProcedureForReport = {
            getProcedureReportData: 'LabInvestigation/getProcedureReportData'
        };
        this.MergePatient = {
            getPatientList: 'OpVisitEntry/getPatientList',
            mergePatient: 'OpVisitEntry/mergePatients'
        };
        this.QueMessage = {
            saveToQue: "Messaging/sendMessageToQueue",
            getPatients: "Messaging/getPatients"
        };
        this.History = {
            getSocialDropdowns: 'Diagnosis/getSocialDropdowns',
            saveSocialHistory: 'Diagnosis/saveSocialHistory',
            deleteSocialHistory: 'Diagnosis/deleteSocialHistory',
            getSocialHistory: 'Diagnosis/getSocialHistory',
            getFamilyHistory: 'Diagnosis/getFamilyHistory',
            saveFamilyHistory: 'Diagnosis/saveFamilyHistory',
            deleteFamilyHistory: 'Diagnosis/deleteFamilyHistory',
        };
        this.Allergy = {
            getAllergenCode: 'Diagnosis/listAllergenCode',
            getAllergyDropdowns: 'Diagnosis/listAllergyDropdowns',
            saveAllergy: 'Diagnosis/saveAllergy',
            deleteAllergy: 'Diagnosis/deleteAllergy',
            getPatientAllergy: 'Diagnosis/listPatientAllergy',
        };
        this.Reports = {
            listExcelExportTypes: 'Report/listExcelExportType',
            listExcelList: 'Report/listExcelExportList'
        };
        this.Country = {
            getCountryCodeWithName: 'InstitutionManagement/getCountryCode'
        };
        this.calender = {
            departmentDoctors: 'Calender/getDepartment',
            getAppointmentList: 'Calender/getAppointmentList',
        };
        this.Templates = {
            getDiagnoDropdowns: 'Templates/getDiagnoDropdowns',
            addTemplete: "Templates/addTemplete",
            getTemplate: "Templates/getTemplate",
            getDoctorByUser: "Templates/getDoctorByUser",
            deleteMedicine: "Templates/deleteMedicine",
            deleteProcedure: "Templates/deleteProcedure",
            deleteDiagnosys: "Templates/deleteDiagnosys",
        };
        this.Laboratory = {
            getDoctors: 'Laboratory/getDoctors',
        };
    }
    ApiService.prototype.extractData = function (res) {
        var body = res;
        return body || {};
    };
    ApiService.prototype.handleError = function (operation, result) {
        if (operation === void 0) { operation = 'operation'; }
        return function (error) {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead
            // TODO: better job of transforming error for user consumption
            console.log(operation + " failed: " + error.message);
            // Let the app keep running by returning an empty result.
            return of(result);
        };
    };
    ApiService.prototype.postRequest = function (url, params) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + url, JSON.stringify(params)).pipe(tap(function (result) {
            return catchError(_this.handleError('getDrSchduleByDate'));
        }));
    };
    ApiService.prototype.searchRequest = function (url, params) {
        return this.http
            .post(environment.API_ENDPOINT + url, JSON.stringify(params)).pipe(map(function (response) { return response['data']; }));
    };
    ApiService.ngInjectableDef = i0.defineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.inject(i1.HttpClient)); }, token: ApiService, providedIn: "root" });
    return ApiService;
}());
export { ApiService };
