import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { environment } from '@env/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};
var LoginService = /** @class */ (function () {
    function LoginService(http) {
        this.http = http;
    }
    LoginService.prototype.extractData = function (res) {
        var body = res;
        return body || {};
    };
    LoginService.prototype.checkCredentials = function (params) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Login/checkCredentials', JSON.stringify(params)).pipe(tap(function (result) {
            return catchError(_this.handleError('checkCredentials'));
        }));
    };
    LoginService.prototype.getAccessTypes = function () {
        return this.http.post(environment.API_ENDPOINT + 'Login/getAccessTypes', null).pipe(map(this.extractData));
    };
    LoginService.prototype.getInstitution = function () {
        return this.http.post(environment.API_ENDPOINT + 'InstitutionManagement/listInstitution', null).pipe(map(this.extractData));
    };
    LoginService.prototype.handleError = function (operation, result) {
        if (operation === void 0) { operation = 'operation'; }
        return function (error) {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead
            // TODO: better job of transforming error for user consumption
            console.log(operation + " failed: " + error.message);
            // Let the app keep running by returning an empty result.
            return of(result);
        };
    };
    LoginService.ngInjectableDef = i0.defineInjectable({ factory: function LoginService_Factory() { return new LoginService(i0.inject(i1.HttpClient)); }, token: LoginService, providedIn: "root" });
    return LoginService;
}());
export { LoginService };
