import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var PatientQueryService = /** @class */ (function () {
    function PatientQueryService(http) {
        this.http = http;
    }
    PatientQueryService.prototype.extractData = function (res) {
        var body = res;
        return body || {};
    };
    PatientQueryService.prototype.getPatientList = function (postData) {
        return this.http.post(environment.API_ENDPOINT + 'Querys/getPatientList', JSON.stringify(postData)).pipe(map(this.extractData));
    };
    PatientQueryService.prototype.handleError = function (operation, result) {
        if (operation === void 0) { operation = 'operation'; }
        return function (error) {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead
            // TODO: better job of transforming error for user consumption
            console.log(operation + " failed: " + error.message);
            // Let the app keep running by returning an empty result.
            return of(result);
        };
    };
    PatientQueryService.ngInjectableDef = i0.defineInjectable({ factory: function PatientQueryService_Factory() { return new PatientQueryService(i0.inject(i1.HttpClient)); }, token: PatientQueryService, providedIn: "root" });
    return PatientQueryService;
}());
export { PatientQueryService };
