/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./timer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./timer.component";
var styles_TimerComponent = [i0.styles];
var RenderType_TimerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TimerComponent, data: {} });
export { RenderType_TimerComponent as RenderType_TimerComponent };
export function View_TimerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "w-100 d-flex justify-content-end"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [["style", "text-align: left;font-weight: bold;font-size: 18px;vertical-align: center;"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", "m ", "s\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.minute; var currVal_1 = _co.second; _ck(_v, 2, 0, currVal_0, currVal_1); }); }
export function View_TimerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-timer", [], null, null, null, View_TimerComponent_0, RenderType_TimerComponent)), i1.ɵdid(1, 114688, null, 0, i2.TimerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TimerComponentNgFactory = i1.ɵccf("app-timer", i2.TimerComponent, View_TimerComponent_Host_0, {}, {}, []);
export { TimerComponentNgFactory as TimerComponentNgFactory };
