import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { environment } from '@env/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
//const endpoint = 'http://wiktait.com/demo/hms/server/';
//const endpoint = 'http://192.168.0.11/hms/server/';
var httpOptions = {
    headers: new HttpHeaders({
        "ignoreLoadingBar": 'true'
    })
};
var AppointmentService = /** @class */ (function () {
    function AppointmentService(http) {
        this.http = http;
    }
    AppointmentService.prototype.extractData = function (res) {
        var body = res;
        return body || {};
    };
    AppointmentService.prototype.getDropdowns = function () {
        return this.http.post(environment.API_ENDPOINT + 'Appointment/options', null).pipe(map(this.extractData));
    };
    AppointmentService.prototype.getDrSchduleByDate = function (params) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Appointment/getDrSchduleByDate', JSON.stringify(params)).pipe(tap(function (result) {
            return catchError(_this.handleError('getDrSchduleByDate'));
        }));
    };
    AppointmentService.prototype.getNextSlot = function (params) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Appointment/getNextSlot', JSON.stringify(params)).pipe(tap(function (result) {
            return catchError(_this.handleError('getNextSlot'));
        }));
    };
    AppointmentService.prototype.getAppontmentSlots = function (params) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Appointment/getAppontmentSlots', JSON.stringify(params)).pipe(tap(function (result) {
            return catchError(_this.handleError('getAppontmentSlots'));
        }));
    };
    AppointmentService.prototype.getDrSchduleForWeek = function (params) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Appointment/getDrSchduleForWeek', JSON.stringify(params)).pipe(tap(function (result) {
            return catchError(_this.handleError('getDrSchduleForWeek'));
        }));
    };
    AppointmentService.prototype.getAppointmentsByDate = function (params) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Appointment/getAppointmentsByDate', JSON.stringify(params), httpOptions).pipe(tap(function (result) {
            return catchError(_this.handleError('getDrSchduleByDate'));
        }));
    };
    AppointmentService.prototype.getAppointmentsByfromtoDate = function (params) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Appointment/getAppointmentsByfromtoDate', JSON.stringify(params)).pipe(tap(function (result) {
            return catchError(_this.handleError('getDrSchduleByDate'));
        }));
    };
    AppointmentService.prototype.downloadgeneralconsent = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Consent/downloadgeneralconsent_reg', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('downloadgeneralconsent_reg'));
        }));
    };
    AppointmentService.prototype.getAppointmentsByDoctor = function (params) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Appointment/getAppointmentsByDoctor', JSON.stringify(params)).pipe(tap(function (result) {
            return catchError(_this.handleError('getAppointmentsByDoctor'));
        }));
    };
    AppointmentService.prototype.cancelAppointment = function (params) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Appointment/cancelAppointment', JSON.stringify(params)).pipe(tap(function (result) {
            return catchError(_this.handleError('cancelAppointment'));
        }));
    };
    AppointmentService.prototype.changeAppointmentStatus = function (params) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Appointment/changeAppointmentStatus', JSON.stringify(params)).pipe(tap(function (result) {
            return catchError(_this.handleError('changeAppointmentStatus'));
        }));
    };
    AppointmentService.prototype.getAllAppointments = function (postData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Appointment/getAllAppointments', JSON.stringify(postData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getAllAppointments'));
        }));
    };
    AppointmentService.prototype.addNewAppointMent = function (appData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Appointment/addNewAppointment', JSON.stringify(appData)).pipe(tap(function (result) {
            return catchError(_this.handleError('addNewOpRegistration'));
        }));
    };
    AppointmentService.prototype.checkAvailableSlots = function (appData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Appointment/checkAvailableSlots', JSON.stringify(appData)).pipe(tap(function (result) {
            return catchError(_this.handleError('checkAvailableSlots'));
        }));
    };
    AppointmentService.prototype.getAppointMent = function (appData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Appointment/getAppointment', JSON.stringify(appData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getAppointMent'));
        }));
    };
    AppointmentService.prototype.getemri = function (appData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'OpRegistration/getemri', JSON.stringify(appData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getemri'));
        }));
    };
    AppointmentService.prototype.getPatientDetails = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'OpRegistration/getPatientDetails', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getPatientDetails'));
        }));
    };
    AppointmentService.prototype.getPatientByEIDnumber = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'OpRegistration/getPatientByEIDnumber', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getPatientByEIDnumber'));
        }));
    };
    AppointmentService.prototype.getPatientsByPhoneNo = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Appointment/getPatientsByPhoneNo', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getPatientsByPhoneNo'));
        }));
    };
    AppointmentService.prototype.getPatientsByName = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Appointment/getPatientsByName', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getPatientsByName'));
        }));
    };
    AppointmentService.prototype.getPatientsByPatientNo = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Appointment/getPatientsByPatientNo', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getPatientsByPatientNo'));
        }));
    };
    AppointmentService.prototype.getDoctersByDate = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Appointment/getDoctersByDate', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getDoctersByDate'));
        }));
    };
    AppointmentService.prototype.getAppointmentsByfromtoDateOpsearch = function (params) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Appointment/getAppointmentsByfromtoDateOpsearch', JSON.stringify(params)).pipe(tap(function (result) {
            return catchError(_this.handleError('getAppointmentsByfromtoDateOpsearch'));
        }));
    };
    AppointmentService.prototype.referalSearchlist = function (params) {
        if (params === '') {
            return of([]);
        }
        return this.http.post(environment.API_ENDPOINT + 'ReferralDoctor/referalSearchlist', JSON.stringify({ search_text: params })).pipe(map(function (response) { return response['data']; }));
    };
    AppointmentService.prototype.getCPT = function (appData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Appointment/getCPT', JSON.stringify(appData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getCPT'));
        }));
    };
    //To handle error
    AppointmentService.prototype.handleError = function (operation, result) {
        if (operation === void 0) { operation = 'operation'; }
        return function (error) {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead
            // TODO: better job of transforming error for user consumption
            console.log(operation + " failed: " + error.message);
            // Let the app keep running by returning an empty result.
            return of(result);
        };
    };
    AppointmentService.ngInjectableDef = i0.defineInjectable({ factory: function AppointmentService_Factory() { return new AppointmentService(i0.inject(i1.HttpClient)); }, token: AppointmentService, providedIn: "root" });
    return AppointmentService;
}());
export { AppointmentService };
