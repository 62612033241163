import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { PageHeaderComponent } from './page-header.component';
import { PrintHeaderComponent } from './print-header/print-header.component';

@NgModule({
    imports: [CommonModule, RouterModule],
    declarations: [PageHeaderComponent, PrintHeaderComponent],
    exports: [PageHeaderComponent,PrintHeaderComponent]
})
export class PageHeaderModule {}
