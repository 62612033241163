import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '@env/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var httpOptions = {
    headers: new HttpHeaders({
        "ignoreLoadingBar": 'true'
    })
};
var ModuleService = /** @class */ (function () {
    function ModuleService(http) {
        this.http = http;
    }
    ModuleService.prototype.extractData = function (res) {
        var body = res;
        return body || {};
    };
    ModuleService.prototype.getModules = function (sentData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Modules/getModules', JSON.stringify(sentData)).pipe(tap(function (result) {
            return catchError(_this.handleError('checkCredentials'));
        }));
    };
    ModuleService.prototype.getModulesNotification = function (sentData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'modules/getNotificationCount', JSON.stringify(sentData), httpOptions).pipe(tap(function (result) {
            return catchError(_this.handleError('checkCredentials'));
        }));
    };
    ModuleService.prototype.getDoctorAssignedToNurseNotificationCount = function (sentData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'modules/getDoctorAssignedToNurseNotificationCount', JSON.stringify(sentData), httpOptions).pipe(tap(function (result) {
            return catchError(_this.handleError('checkCredentials'));
        }));
    };
    ModuleService.prototype.getModuleSummary = function (sentData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'modules/getModuleSummary', JSON.stringify(sentData), httpOptions).pipe(tap(function (result) {
            return catchError(_this.handleError('getModuleSummary'));
        }));
    };
    ModuleService.prototype.changePassword = function (sentData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'UserManagement/changePassword', JSON.stringify(sentData)).pipe(tap(function (result) {
            return catchError(_this.handleError('changePassword'));
        }));
    };
    ModuleService.prototype.handleError = function (operation, result) {
        if (operation === void 0) { operation = 'operation'; }
        return function (error) {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead
            // TODO: better job of transforming error for user consumption
            console.log(operation + " failed: " + error.message);
            // Let the app keep running by returning an empty result.
            return of(result);
        };
    };
    ModuleService.ngInjectableDef = i0.defineInjectable({ factory: function ModuleService_Factory() { return new ModuleService(i0.inject(i1.HttpClient)); }, token: ModuleService, providedIn: "root" });
    return ModuleService;
}());
export { ModuleService };
