import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { environment } from '@env/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
// const endpoint = 'http://192.168.0.11/hms/server/';
// const endpoint = 'http://wiktait.com/demo/hms/server/';
var httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};
var OpRegistrationService = /** @class */ (function () {
    function OpRegistrationService(http) {
        this.http = http;
    }
    OpRegistrationService.prototype.extractData = function (res) {
        var body = res;
        return body || {};
    };
    OpRegistrationService.prototype.saveBillresult = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'NewBill/saveBillresult', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('saveBillresult'));
        }));
    };
    // claim process
    OpRegistrationService.prototype.unClaimedInvoiceList = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'InsuranceClaim/nonClaimedinvoiceList', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('unClaimedInvoiuceList'));
        }));
    };
    OpRegistrationService.prototype.updateInsuranceDetails = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'OpRegistration/updateInsuranceDetails', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('updateInsuranceDetails'));
        }));
    };
    OpRegistrationService.prototype.getFileContent = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'InsuranceClaim/getFileContent', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getFileContent'));
        }));
    };
    OpRegistrationService.prototype.saveFileContent = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'InsuranceClaim/saveFileContent', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('saveFileContent'));
        }));
    };
    OpRegistrationService.prototype.download = function (url) {
        var headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/xml');
        return this.http.get(url, { headers: headers, responseType: 'blob' });
    };
    OpRegistrationService.prototype.XmlClaimList = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'InsuranceClaim/submissionFileList', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('XmlClaimList'));
        }));
    };
    OpRegistrationService.prototype.submittedFileList = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'InsuranceClaim/submittedFileList', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('submittedFileList'));
        }));
    };
    OpRegistrationService.prototype.searchTransactionParams = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'InsuranceClaim/searchTransactionParams', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('searchTransactionParams'));
        }));
    };
    OpRegistrationService.prototype.GetNewTransactions = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'InsuranceClaim/GetNewTransactions', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('GetNewTransactions'));
        }));
    };
    OpRegistrationService.prototype.searchNewTransactions = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'InsuranceClaim/getNewRemittance', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('searchNewTransactions'));
        }));
    };
    OpRegistrationService.prototype.GenerateSubmissionFiles = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'InsuranceClaim/generateSubmissionFile', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('GenerateSubmissionFiles'));
        }));
    };
    OpRegistrationService.prototype.testUploadSubmissionFile = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'InsuranceClaim/testUploadSubmissionFile', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('testUploadSubmissionFile'));
        }));
    };
    OpRegistrationService.prototype.UploadSubmissionFile = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'InsuranceClaim/UploadSubmissionFile', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('testUploadSubmissionFile'));
        }));
    };
    OpRegistrationService.prototype.reGenerateSubmissionXml = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'InsuranceClaim/reGenerateSubmissionXml', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('SubmissionXmlList'));
        }));
    };
    OpRegistrationService.prototype.getOpDropdowns = function () {
        return this.http.post(environment.API_ENDPOINT + 'OpRegistration/options', null).pipe(map(this.extractData));
    };
    // getOpNetworks(opdata): Observable<any> {
    //   return this.http.post(environment.API_ENDPOINT + 'OpRegistration/getNetworkDetails', null).pipe(
    //     map(this.extractData));
    // }
    OpRegistrationService.prototype.listCPT = function () {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'CurrentProceduralCode/listCurrentProceduralCode', null).pipe(tap(function (result) {
            return catchError(_this.handleError('listCurrentProceduralCode'));
        }));
    };
    OpRegistrationService.prototype.listCurrentProceduralCodeforTreatment = function () {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'CurrentProceduralCode/listCurrentProceduralCodeforTreatment', null).pipe(tap(function (result) {
            return catchError(_this.handleError('listCurrentProceduralCodeforTreatment'));
        }));
    };
    OpRegistrationService.prototype.getOpNetworks = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'OpRegistration/getNetworkDetails', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getOpNetworks'));
        }));
    };
    OpRegistrationService.prototype.addNewOpRegistration = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'OpRegistration/newRegistration', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('addNewOpRegistration'));
        }));
    };
    OpRegistrationService.prototype.isExistEidNumber = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'OpRegistration/isExistEidNumber', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('addNewOpRegistration'));
        }));
    };
    OpRegistrationService.prototype.getPatientDetails = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'OpRegistration/getPatientDetails', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getPatientDetails'));
        }));
    };
    OpRegistrationService.prototype.getBillVarificationDetails = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'InsuranceClaim/GetBillverificationDetails', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getBillVarificationDetails'));
        }));
    };
    OpRegistrationService.prototype.searchTransactions = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'InsuranceClaim/searchTransactions', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('searchTransactions'));
        }));
    };
    OpRegistrationService.prototype.updateBillverificationData = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'InsuranceClaim/updateBillverificationData', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('updateBillverificationData'));
        }));
    };
    OpRegistrationService.prototype.confirmBillverificationData = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'InsuranceClaim/confirmBillverificationData', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('confirmBillverificationData'));
        }));
    };
    OpRegistrationService.prototype.checkInsMemberId = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'OpRegistration/checkInsMemberId', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('checkInsMemberId'));
        }));
    };
    OpRegistrationService.prototype.saveEncounterType = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'InsuranceClaim/saveEncounterType', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('saveEncounterType'));
        }));
    };
    OpRegistrationService.prototype.getEncounterType = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'InsuranceClaim/getEncounterType', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getEncounterType'));
        }));
    };
    OpRegistrationService.prototype.deleteSubmissionFile = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'InsuranceClaim/deleteSubmissionFile', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('deleteSubmissionFile'));
        }));
    };
    // To handle error
    OpRegistrationService.prototype.handleError = function (operation, result) {
        if (operation === void 0) { operation = 'operation'; }
        return function (error) {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead
            // TODO: better job of transforming error for user consumption
            console.log(operation + " failed: " + error.message);
            // Let the app keep running by returning an empty result.
            return of(result);
        };
    };
    OpRegistrationService.ngInjectableDef = i0.defineInjectable({ factory: function OpRegistrationService_Factory() { return new OpRegistrationService(i0.inject(i1.HttpClient)); }, token: OpRegistrationService, providedIn: "root" });
    return OpRegistrationService;
}());
export { OpRegistrationService };
