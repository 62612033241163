import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '@env/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};
var cpt_path1 = environment.API_ENDPOINT + 'Querys/getPatientList';
// const WIKI_URL = 'https://en.wikipedia.org/w/api.php';
var cpt_path = environment.API_ENDPOINT + 'CurrentProceduralCode/listCurrentProceduralCodeforTreatment';
var diagnosis_path = environment.API_ENDPOINT + 'Diagnosis/listDiagnosis';
var medicine_path = environment.API_ENDPOINT + 'Medicine/listMedicine';
var generic_path = environment.API_ENDPOINT + 'Medicine/listMedicine';
var vaccine_path = environment.API_ENDPOINT + 'Immunization/listImmunization';
var routeofadmin_path = environment.API_ENDPOINT + '/DoctorPrescription/listRouteOfAdmin';
var PARAMS = new HttpParams({
    fromObject: {
        action: 'opensearch',
        format: 'json',
        origin: '*'
    }
});
var CalenderService = /** @class */ (function () {
    function CalenderService(http) {
        this.http = http;
    }
    CalenderService.prototype.getDepartment = function (params) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Calender/getDepartment', JSON.stringify(params)).pipe(tap(function (result) {
            return catchError(_this.handleError('getDepartment'));
        }));
    };
    CalenderService.prototype.getDepartmentDoctor = function () {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Calender/getDepartmentDoctors', null).pipe(tap(function (result) {
            return catchError(_this.handleError('getDepartmentDoctor'));
        }));
    };
    CalenderService.prototype.getDepartmentDoctorsNew = function () {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Calender/getDepartmentDoctorsNew', null).pipe(tap(function (result) {
            return catchError(_this.handleError('getDepartmentDoctorsNew'));
        }));
    };
    CalenderService.prototype.getAppointmentsByDate = function (params) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Calender/getAppointmentsByDate', JSON.stringify(params)).pipe(tap(function (result) {
            return catchError(_this.handleError('getAppointmentsByDate'));
        }));
    };
    CalenderService.prototype.getAppointments = function (params) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Calender/getAppointments', JSON.stringify(params)).pipe(tap(function (result) {
            return catchError(_this.handleError('getAppointments'));
        }));
    };
    CalenderService.prototype.handleError = function (operation, result) {
        if (operation === void 0) { operation = 'operation'; }
        return function (error) {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead
            // TODO: better job of transforming error for user consumption
            console.log(operation + " failed: " + error.message);
            // Let the app keep running by returning an empty result.
            return of(result);
        };
    };
    CalenderService.ngInjectableDef = i0.defineInjectable({ factory: function CalenderService_Factory() { return new CalenderService(i0.inject(i1.HttpClient)); }, token: CalenderService, providedIn: "root" });
    return CalenderService;
}());
export { CalenderService };
