import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditorComponent } from './editor.component';
import { HttpClientModule} from '@angular/common/http';
// import { AngularEditorModule } from '@kolkov/angular-editor';
import { FormsModule,ReactiveFormsModule } from "@angular/forms";
// import { NgxEditorModule } from 'ngx-editor';
// import { NgxEditorModule } from "ngx-editor";
// import { NgxEditorModule } from 'ng6x-editor';
// import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
// import { CKEditorModule } from 'ckeditor4-angular';
// import { NgxEditorModule } from 'ngx-editor';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

@NgModule({
  declarations: [EditorComponent],
  imports: [
    CommonModule,HttpClientModule,CKEditorModule,FormsModule,ReactiveFormsModule
  ],
  exports: [
    EditorComponent
  ],
})
export class EditorModule { }
