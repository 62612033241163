import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { environment } from '@env/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};
var NursingAssesmentService = /** @class */ (function () {
    function NursingAssesmentService(http) {
        this.http = http;
    }
    NursingAssesmentService.prototype.extractData = function (res) {
        var body = res;
        return body || {};
    };
    NursingAssesmentService.prototype.getOpDropdowns = function () {
        return this.http.post(environment.API_ENDPOINT + 'OpRegistration/options', null).pipe(map(this.extractData));
    };
    NursingAssesmentService.prototype.startAssesment = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'NursingAssessment/startAssesment', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('startAssesment'));
        }));
    };
    NursingAssesmentService.prototype.downloadgeneralconsent = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Consent/downloadgeneralconsent', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('downloadgeneralconsent'));
        }));
    };
    NursingAssesmentService.prototype.cancelVisit = function (params) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'NursingAssessment/cancelVisit', JSON.stringify(params)).pipe(tap(function (result) {
            return catchError(_this.handleError('cancelVisit'));
        }));
    };
    NursingAssesmentService.prototype.getPatientDetails = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'OpRegistration/getPatientDetails', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getPatientDetails'));
        }));
    };
    NursingAssesmentService.prototype.getCPT = function (postdata) {
        return this.http.post(environment.API_ENDPOINT + 'CurrentProceduralCode/getCurrentProceduralCode', JSON.stringify(postdata)).pipe(map(this.extractData));
    };
    NursingAssesmentService.prototype.getCPTByCode = function (postdata) {
        return this.http.post(environment.API_ENDPOINT + 'CurrentProceduralCode/getCPTByCode', JSON.stringify(postdata)).pipe(map(this.extractData));
    };
    NursingAssesmentService.prototype.getCurrentDentalByDentalCode = function (postdata) {
        return this.http.post(environment.API_ENDPOINT + 'CurrentProceduralCode/getCurrentDentalByDentalCode', JSON.stringify(postdata)).pipe(map(this.extractData));
    };
    NursingAssesmentService.prototype.getAssesmentListByDate = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'NursingAssessment/getAssesmentListByDate', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getPatientDetails'));
        }));
    };
    NursingAssesmentService.prototype.getDoctorAssesmentListByDate = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'NursingAssessment/getDoctorAssesmentListByDate', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getDoctorAssesmentListByDate'));
        }));
    };
    NursingAssesmentService.prototype.getAssesmentParameters = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'NursingAssessment/getAssesmentParameters', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getPatientDetails'));
        }));
    };
    NursingAssesmentService.prototype.getBloodSugarReport = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'NursingAssessment/getBloodSugarReport', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('BloodSugarReport'));
        }));
    };
    NursingAssesmentService.prototype.getAssesmentParameterValues = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'NursingAssessment/getAssesmentParameterValues', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getPatientDetails'));
        }));
    };
    NursingAssesmentService.prototype.updateNursingNoteStatus = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'LabInvestigation/updateNursingNoteStatus', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('updateNursingNoteStatus'));
        }));
    };
    NursingAssesmentService.prototype.getAssessmentMenus = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'NursingAssessment/getAssessmentMenus', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getPatientDetails'));
        }));
    };
    NursingAssesmentService.prototype.get_sub_modules = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Settings/get_sub_modules', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getPatientDetails'));
        }));
    };
    NursingAssesmentService.prototype.editAssesmentValues = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'NursingAssessment/editAssesmentValues', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getPatientDetails'));
        }));
    };
    NursingAssesmentService.prototype.saveAssesmentParameters = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'NursingAssessment/saveAssesmentParameters', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getPatientDetails'));
        }));
    };
    NursingAssesmentService.prototype.saveBloodSugarReport = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'NursingAssessment/saveBloodSugarReport', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('saveBloodSugarReport'));
        }));
    };
    NursingAssesmentService.prototype.saveDocuments = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'FileManage/saveFile', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('Document error'));
        }));
    };
    NursingAssesmentService.prototype.deleteDocuments = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'FileManage/deleteFile', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('Document error'));
        }));
    };
    NursingAssesmentService.prototype.getDocuments = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'FileManage/listFiles', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('Document error'));
        }));
    };
    NursingAssesmentService.prototype.getReports = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Laboratory/listReports', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('Document error'));
        }));
    };
    NursingAssesmentService.prototype.getradiologyReports = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Laboratory/getradiologyReports', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('Document error'));
        }));
    };
    NursingAssesmentService.prototype.saveAssesmentNotes = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'NursingAssessmentNotes/saveAssesmentParameters', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getPatientDetails'));
        }));
    };
    NursingAssesmentService.prototype.saveProgressNotes = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'LabInvestigation/saveProgressNotes', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('saveProgressNotes'));
        }));
    };
    NursingAssesmentService.prototype.getNotesParameters = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'NursingAssessmentNotes/getAssesmentParameters', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getNotesParameters'));
        }));
    };
    NursingAssesmentService.prototype.editNotesAssesmentValues = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'NursingAssessmentNotes/editAssesmentValues', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('editNotesAssesmentValues'));
        }));
    };
    NursingAssesmentService.prototype.listPatientAllergies = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'PatientAllergies/listPatientAllergies', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('listPatientAllergies'));
        }));
    };
    NursingAssesmentService.prototype.getPatientAllergies = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'PatientAllergies/getPatientAllergies', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getPatientAllergies'));
        }));
    };
    NursingAssesmentService.prototype.savePatientAllergies = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'PatientAllergies/savePatientAllergies', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('savePatientAllergies'));
        }));
    };
    NursingAssesmentService.prototype.deletePatientAllergies = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'PatientAllergies/deletePatientAllergies', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('deletePatientAllergies'));
        }));
    };
    NursingAssesmentService.prototype.listAllergiesOther = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'AllergiesOther/listAllergiesOther', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('listAllergiesOther'));
        }));
    };
    NursingAssesmentService.prototype.saveAllergiesOther = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'AllergiesOther/saveAllergiesOther', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('saveAllergiesOther'));
        }));
    };
    NursingAssesmentService.prototype.deleteAllergiesOther = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'AllergiesOther/deleteAllergiesOther', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('deleteAllergiesOther'));
        }));
    };
    NursingAssesmentService.prototype.completeAssessment = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'NursingAssessment/completeAssesment', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('completeAssessment'));
        }));
    };
    NursingAssesmentService.prototype.completeDoctorAssesment = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'NursingAssessment/completeDoctorAssesment', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('completeDoctorAssesment'));
        }));
    };
    NursingAssesmentService.prototype.getPreviousAssessmentDetails = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'NursingAssessment/getPreviousAssessmentDetails', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getPreviousAssessmentDetails'));
        }));
    };
    NursingAssesmentService.prototype.getNextAssessmentDetails = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'NursingAssessment/getNextAssessmentDetails', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getNextAssessmentDetails'));
        }));
    };
    NursingAssesmentService.prototype.getDiscountTreatmentDetails = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'NursingAssessment/getDiscountTreatmentDetails', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getDiscountTreatmentDetails'));
        }));
    };
    NursingAssesmentService.prototype.getallVisitDetails = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'NursingAssessment/getallVisitDetails', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getallVisitDetails'));
        }));
    };
    NursingAssesmentService.prototype.getAssessmentDetails = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'NursingAssessment/getAssessmentDetails', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getAssessmentDetails'));
        }));
    };
    NursingAssesmentService.prototype.savePainAssesment = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'PainAssessment/savePainAssesment', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('savePainAssesment'));
        }));
    };
    NursingAssesmentService.prototype.getPainAssesment = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'PainAssessment/getPainAssesment', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getPainAssesment'));
        }));
    };
    NursingAssesmentService.prototype.getPainAssesments = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'PainAssessment/getPainAssesments', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getPainAssesments'));
        }));
    };
    NursingAssesmentService.prototype.get_master_data = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'PainAssessment/get_master_data', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('get_master_data'));
        }));
    };
    NursingAssesmentService.prototype.saveNurseProcedureStatus = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'LabInvestigation/saveNurseProcedureStatus', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('savePainAssesment'));
        }));
    };
    NursingAssesmentService.prototype.setBackAppointment = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Appointment/setBackVisit', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('setBackVisit'));
        }));
    };
    NursingAssesmentService.prototype.getPatientData = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'OpVisitEntry/getPatientData', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getPatientData'));
        }));
    };
    NursingAssesmentService.prototype.getProcedurePatient = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'OpRegistration/getPatientDetails', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getProcedurePatient'));
        }));
    };
    //To handle error
    NursingAssesmentService.prototype.handleError = function (operation, result) {
        if (operation === void 0) { operation = 'operation'; }
        return function (error) {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead
            // TODO: better job of transforming error for user consumption
            console.log(operation + " failed: " + error.message);
            // Let the app keep running by returning an empty result.
            return of(result);
        };
    };
    NursingAssesmentService.ngInjectableDef = i0.defineInjectable({ factory: function NursingAssesmentService_Factory() { return new NursingAssesmentService(i0.inject(i1.HttpClient)); }, token: NursingAssesmentService, providedIn: "root" });
    return NursingAssesmentService;
}());
export { NursingAssesmentService };
