import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpErrorResponse, HttpParams} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { environment } from '@env/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

const PARAMS = new HttpParams({
  fromObject: {
    action: 'opensearch',
    format: 'json',
    origin: '*'
  }
});

@Injectable({
  providedIn: 'root'
})
export class ReferralDoctorService {
  [x: string]: any;

  constructor(private http: HttpClient) { }

  listReferralDoctor (opData): Observable<any> {
    
    return this.http.post<any>(environment.API_ENDPOINT + 'ReferralDoctor/referalDoctorList', JSON.stringify(opData)).pipe(
      tap((result) =>
        catchError(this.handleError<any>('listReferralDoctor'))
      ));
  }

  getReferralDoctor (opData): Observable<any> {
    
    return this.http.post<any>(environment.API_ENDPOINT + 'ReferralDoctor/getReferralDoctor', JSON.stringify(opData)).pipe(
      tap((result) =>
        catchError(this.handleError<any>('getReferralDoctor'))
      ));
  }

  saveReferral (opData): Observable<any> {
    
    return this.http.post<any>(environment.API_ENDPOINT + 'ReferralDoctor/saveReferralDoctor', JSON.stringify(opData)).pipe(
      tap((result) =>
        catchError(this.handleError<any>('saveReferral'))
      ));
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }


}
