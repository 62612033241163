import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { environment } from '@env/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var UserManagementService = /** @class */ (function () {
    function UserManagementService(http) {
        this.http = http;
    }
    UserManagementService.prototype.extractData = function (res) {
        var body = res;
        return body || {};
    };
    UserManagementService.prototype.getOptions = function () {
        return this.http.post(environment.API_ENDPOINT + 'UserManagement/options', null).pipe(map(this.extractData));
    };
    UserManagementService.prototype.getUserList = function () {
        return this.http.post(environment.API_ENDPOINT + 'UserManagement/getUserList', null).pipe(tap(function (result) { return console.log("updated ins data w/ stat=" + result.status); }), catchError(this.handleError('getUserList')));
    };
    UserManagementService.prototype.addNewUser = function (sendData) {
        return this.http.post(environment.API_ENDPOINT + 'UserManagement/createNewUser', JSON.stringify(sendData)).pipe(tap(function (result) { return console.log("updated ins data w/ stat=" + result.status); }), catchError(this.handleError('getAccessrights')));
    };
    UserManagementService.prototype.getUser = function (sendData) {
        return this.http.post(environment.API_ENDPOINT + 'UserManagement/getUser', JSON.stringify(sendData)).pipe(tap(function (result) { return console.log("updated ins data w/ stat=" + result.status); }), catchError(this.handleError('getAccessrights')));
    };
    UserManagementService.prototype.saveInstitution = function (sendData) {
        return this.http.post(environment.API_ENDPOINT + 'InstitutionManagement/saveInstitution', JSON.stringify(sendData)).pipe(tap(function (result) { return console.log("updated ins data w/ stat=" + result.status); }), catchError(this.handleError('saveInstitution')));
    };
    UserManagementService.prototype.saveCustomDate = function (sendData) {
        return this.http.post(environment.API_ENDPOINT + 'InstitutionManagement/saveCustomDate', JSON.stringify(sendData)).pipe(tap(function (result) { return console.log("updated ins data w/ stat=" + result.status); }), catchError(this.handleError('saveCustomDate')));
    };
    UserManagementService.prototype.listInstitution = function () {
        return this.http.post(environment.API_ENDPOINT + 'InstitutionManagement/listInstitution', null).pipe(map(this.extractData));
    };
    UserManagementService.prototype.getHospitalOptions = function () {
        return this.http.post(environment.API_ENDPOINT + 'InstitutionManagement/Options', null).pipe(map(this.extractData));
    };
    UserManagementService.prototype.handleError = function (operation, result) {
        if (operation === void 0) { operation = 'operation'; }
        return function (error) {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead
            // TODO: better job of transforming error for user consumption
            console.log(operation + " failed: " + error.message);
            // Let the app keep running by returning an empty result.
            return of(result);
        };
    };
    UserManagementService.ngInjectableDef = i0.defineInjectable({ factory: function UserManagementService_Factory() { return new UserManagementService(i0.inject(i1.HttpClient)); }, token: UserManagementService, providedIn: "root" });
    return UserManagementService;
}());
export { UserManagementService };
