import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { environment } from '@env/environment';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};
const cpt_path1 = environment.API_ENDPOINT + 'Querys/getPatientList';
// const WIKI_URL = 'https://en.wikipedia.org/w/api.php';
const cpt_path = environment.API_ENDPOINT + 'CurrentProceduralCode/listCurrentProceduralCodeforTreatment';
const diagnosis_path = environment.API_ENDPOINT + 'Diagnosis/listDiagnosis';
const medicine_path = environment.API_ENDPOINT + 'Medicine/listMedicine';
const generic_path = environment.API_ENDPOINT + 'Medicine/listMedicine';
const vaccine_path = environment.API_ENDPOINT + 'Immunization/listImmunization';
const routeofadmin_path = environment.API_ENDPOINT + '/DoctorPrescription/listRouteOfAdmin';
const PARAMS = new HttpParams({
  fromObject: {
    action: 'opensearch',
    format: 'json',
    origin: '*'
  }
});

@Injectable({
  providedIn: 'root'
})
export class CalenderService {

  constructor(private http: HttpClient) { }

  getDepartment (params): Observable<any> {
    
    return this.http.post<any>(environment.API_ENDPOINT + 'Calender/getDepartment', JSON.stringify(params)).pipe(
      tap((result) =>
        catchError(this.handleError<any>('getDepartment'))
      ));
  }
getDepartmentDoctor (): Observable<any> {
    
    return this.http.post<any>(environment.API_ENDPOINT + 'Calender/getDepartmentDoctors', null).pipe(
      tap((result) =>
        catchError(this.handleError<any>('getDepartmentDoctor'))
      ));
  }
  getDepartmentDoctorsNew (): Observable<any> {
    
    return this.http.post<any>(environment.API_ENDPOINT + 'Calender/getDepartmentDoctorsNew', null).pipe(
      tap((result) =>
        catchError(this.handleError<any>('getDepartmentDoctorsNew'))
      ));
  }


  getAppointmentsByDate (params): Observable<any> {
    
    return this.http.post<any>(environment.API_ENDPOINT + 'Calender/getAppointmentsByDate', JSON.stringify(params)).pipe(
      tap((result) =>
        catchError(this.handleError<any>('getAppointmentsByDate'))
      ));
  }

  getAppointments (params): Observable<any> {
    
    return this.http.post<any>(environment.API_ENDPOINT + 'Calender/getAppointments', JSON.stringify(params)).pipe(
      tap((result) =>
        catchError(this.handleError<any>('getAppointments'))
      ));
  }
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
