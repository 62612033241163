import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { environment } from '@env/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var UserAccessService = /** @class */ (function () {
    function UserAccessService(http) {
        this.http = http;
    }
    UserAccessService.prototype.extractData = function (res) {
        var body = res;
        return body || {};
    };
    UserAccessService.prototype.getOptions = function () {
        return this.http.post(environment.API_ENDPOINT + 'UserAccess/options', null).pipe(map(this.extractData));
    };
    UserAccessService.prototype.getAccessrights = function (sendData) {
        return this.http.post(environment.API_ENDPOINT + 'UserAccess/getAccessData', JSON.stringify(sendData)).pipe(tap(function (result) { return console.log("updated ins data w/ stat=" + result.status); }), catchError(this.handleError('getAccessrights')));
    };
    UserAccessService.prototype.changeAccessGroup = function (sendData) {
        return this.http.post(environment.API_ENDPOINT + 'UserAccess/changeAccessGroup', JSON.stringify(sendData)).pipe(tap(function (result) { return console.log("updated ins data w/ stat=" + result.status); }), catchError(this.handleError('getAccessrights')));
    };
    UserAccessService.prototype.changeAccessRights = function (sendData) {
        return this.http.post(environment.API_ENDPOINT + 'UserAccess/changeAccessRights', JSON.stringify(sendData)).pipe(tap(function (result) { return console.log("updated ins data w/ stat=" + result.status); }), catchError(this.handleError('getAccessrights')));
    };
    UserAccessService.prototype.handleError = function (operation, result) {
        if (operation === void 0) { operation = 'operation'; }
        return function (error) {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead
            // TODO: better job of transforming error for user consumption
            console.log(operation + " failed: " + error.message);
            // Let the app keep running by returning an empty result.
            return of(result);
        };
    };
    UserAccessService.ngInjectableDef = i0.defineInjectable({ factory: function UserAccessService_Factory() { return new UserAccessService(i0.inject(i1.HttpClient)); }, token: UserAccessService, providedIn: "root" });
    return UserAccessService;
}());
export { UserAccessService };
