import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { environment } from '@env/environment';
const httpOptions = {
  headers: new HttpHeaders({
    "ignoreLoadingBar": 'true'
  })
};


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public FileManage = {
    otherDocuments : "FileManage/listOtherDocuments"
  }
  public Settings = {
    masterData : "Settings/get_master_data"
  }
  public ConsentForms = {
    departments : "Forms/listDepartments",
    saveForm : "Forms/saveForm",
    listForms: "Forms/listForms",
    cpts:"CurrentProceduralCode/listCurrentProceduralCodeforTreatment"
  }
  public OpVisit= {
    VisitStates: "OpVisitEntry/getPatientVisitStates",
    formData: "OpVisitEntry/getPatientConsentFormById",
    unsettledBillCheck: "NewBill/checkUnsettledBill",
      depatmentDoctors: "OpVisitEntry/getDrByDateDept"
  }
  public Doctor = {
    DoctorsDepartment:"Doctors/getDoctorDepartment",
    PatientConsent:"Doctors/getConsents",
    DoctorCategory:"Doctors/getDoctorCategory",
    DeleteProcedure:"Consultation/deleteCompletedProcedure",
    getPatientEmrStat:"PatientDiagnosis/getPatientEmrStat"
}
  public Medicine= {
    routeOfAdmin: "Medicine/getRouteOfAdmin",
    getMedicine: "Medicine/getMedicine"
  }
  public visitStatus= {
    listVisitStatus: 'Department/listVisitStatus',
    saveVisitStatus: 'Department/saveVisitStatus',
    departments: 'Department/listDepartment',
  }
  public Dental={
    getToothStructure:'DentalComplaints/getToothStructure',
    getPlanToothStructure:'DentalComplaints/getPlanToothStructure',
    getAllDentalHistory:'DentalComplaints/getAllDentalHistory',
    deleteDentalProcedure:'DentalComplaints/deleteDentalInvestigation'
  }
  public ProcedureForReport={
    getProcedureReportData:'LabInvestigation/getProcedureReportData'
  }
  public MergePatient={
    getPatientList:'OpVisitEntry/getPatientList',
    mergePatient:'OpVisitEntry/mergePatients'
  }
  public QueMessage={
    saveToQue:"Messaging/sendMessageToQueue",
    getPatients:"Messaging/getPatients"
  }
  public History={
    getSocialDropdowns:'Diagnosis/getSocialDropdowns',
    saveSocialHistory:'Diagnosis/saveSocialHistory',
    deleteSocialHistory:'Diagnosis/deleteSocialHistory',
    getSocialHistory:'Diagnosis/getSocialHistory',
    getFamilyHistory:'Diagnosis/getFamilyHistory',
    saveFamilyHistory:'Diagnosis/saveFamilyHistory',
    deleteFamilyHistory:'Diagnosis/deleteFamilyHistory',
  }
  public Allergy={
    getAllergenCode:'Diagnosis/listAllergenCode',
    getAllergyDropdowns:'Diagnosis/listAllergyDropdowns',
    saveAllergy:'Diagnosis/saveAllergy',
    deleteAllergy:'Diagnosis/deleteAllergy',
    getPatientAllergy:'Diagnosis/listPatientAllergy',
  }
  public Reports={
    listExcelExportTypes:'Report/listExcelExportType',
    listExcelList:'Report/listExcelExportList'
  }
  public Country= {
  getCountryCodeWithName: 'InstitutionManagement/getCountryCode'
}
public calender={
    departmentDoctors:'Calender/getDepartment',
    getAppointmentList:'Calender/getAppointmentList',
 }
 public Templates={
   getDiagnoDropdowns:'Templates/getDiagnoDropdowns',
   addTemplete : "Templates/addTemplete",
   getTemplate : "Templates/getTemplate",
   getDoctorByUser : "Templates/getDoctorByUser",
   deleteMedicine : "Templates/deleteMedicine",
   deleteProcedure : "Templates/deleteProcedure",
   deleteDiagnosys : "Templates/deleteDiagnosys",
 }
 public Laboratory={
   getDoctors:'Laboratory/getDoctors',
 }
  constructor(private http: HttpClient) { }




  private extractData(res: Response) {
    let body = res;
    return body || { };
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  postRequest (url,params): Observable<any> {

    return this.http.post<any>(environment.API_ENDPOINT + url, JSON.stringify(params)).pipe(
      tap((result) =>
      catchError(this.handleError<any>('getDrSchduleByDate'))
    ));
  }

  searchRequest(url,params) {
    return this.http
      .post<any>(environment.API_ENDPOINT + url, JSON.stringify( params)).pipe(
        map(response => response['data'])
      );
  }
}
