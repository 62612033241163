import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { environment } from '@env/environment';
// const endpoint = 'http://192.168.0.11/hms/server/';
// const endpoint = 'http://wiktait.com/demo/hms/server/';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})

export class DentalTreatmentService {

  constructor(private http: HttpClient) { }
  private extractData(res: Response) {
    const body = res;
    return body || { };
  }

  saveDentalTreatment(opData): Observable<any> {
    // console.log("11111111111111111111111111");
    return this.http.post<any>(environment.API_ENDPOINT + 'DentalTreatment/saveDentalTreatment', JSON.stringify(opData)).pipe(
      tap((result) =>
        catchError(this.handleError<any>('saveDentalTreatment'))
      ));
  }

  listDentalTreatments(opData): Observable<any> {
    return this.http.post<any>(environment.API_ENDPOINT + 'DentalTreatment/listDentalTreatments', JSON.stringify(opData)).pipe(
      tap((result) =>
        catchError(this.handleError<any>('listDentalTreatments'))
      ));
  }

  // listDentalDepartments (): Observable<any> {
  //   return this.http.post<any>(environment.API_ENDPOINT + 'DentalTreatment/listDentalDepartments', null).pipe(
  //     tap((result) =>
  //       catchError(this.handleError<any>('listDentalDepartments'))
  //     ));
  // }





  // To handle error
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
