import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { environment } from '@env/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var httpOptions = {
    headers: new HttpHeaders({
        "ignoreLoadingBar": 'true'
    })
};
var DoctorsService = /** @class */ (function () {
    function DoctorsService(http) {
        this.http = http;
    }
    DoctorsService.prototype.medicine_search = function (term) {
        throw new Error("Method not implemented.");
    };
    DoctorsService.prototype.extractData = function (res) {
        var body = res;
        return body || {};
    };
    DoctorsService.prototype.getDropdowns = function () {
        return this.http.post(environment.API_ENDPOINT + 'Doctors/options', null).pipe(map(this.extractData));
    };
    DoctorsService.prototype.addNewDoctor = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Doctors/addNewDoctor', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('addNewDoctor'));
        }));
    };
    DoctorsService.prototype.deleteDoctor = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Doctors/deleteDoctor', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('deleteDoctor'));
        }));
    };
    DoctorsService.prototype.getDoctorList = function (postData) {
        return this.http.post(environment.API_ENDPOINT + 'Doctors/getDoctorList', JSON.stringify(postData)).pipe(map(this.extractData));
    };
    DoctorsService.prototype.save_diagnosis = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Diagnosis/saveDiagnosis', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('savecomplaints'));
        }));
    };
    DoctorsService.prototype.getDiagnosisList = function (postdata) {
        return this.http.post(environment.API_ENDPOINT + 'Diagnosis/listDiagnosis', null).pipe(map(this.extractData));
    };
    DoctorsService.prototype.getDiagnosis = function (postdata) {
        return this.http.post(environment.API_ENDPOINT + 'Diagnosis/getDiagnosis', null).pipe(map(this.extractData));
    };
    DoctorsService.prototype.saveMedicine = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + '/Medicine/saveMedicine', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('savecomplaints'));
        }));
    };
    DoctorsService.prototype.getMedicinelist = function (postdata) {
        return this.http.post(environment.API_ENDPOINT + 'Medicine/listMedicine', null).pipe(map(this.extractData));
    };
    DoctorsService.prototype.getUnit = function () {
        return this.http.post(environment.API_ENDPOINT + 'Medicine/getUnits', null).pipe(map(this.extractData));
    };
    DoctorsService.prototype.getUnits = function (opData) {
        return this.http.post(environment.API_ENDPOINT + 'DoctorPrescription/getUnitList', JSON.stringify({ search_text: opData })).pipe(map(function (response) { return response['data']; }));
    };
    DoctorsService.prototype.saveCPT = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'CurrentProceduralCode/saveCurrentProceduralCode', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('savecomplaints'));
        }));
    };
    // getCPTlist(postdata): Observable<any> {
    //   return this.http.post(environment.API_ENDPOINT + 'CurrentProceduralCode/listCurrentProceduralCode',null).pipe(
    //     map(this.extractData));
    // }
    DoctorsService.prototype.getCPTlist = function (postdata) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'CurrentProceduralCode/listCurrentProceduralCode', JSON.stringify(postdata)).pipe(tap(function (result) {
            return catchError(_this.handleError('getcpt'));
        }));
    };
    DoctorsService.prototype.getCPT = function (postdata) {
        return this.http.post(environment.API_ENDPOINT + 'CurrentProceduralCode/getCurrentProceduralCode', JSON.stringify(postdata)).pipe(map(this.extractData));
    };
    DoctorsService.prototype.getcpt_group = function () {
        return this.http.post(environment.API_ENDPOINT + 'CurrentProceduralCode/options', null).pipe(map(this.extractData));
    };
    DoctorsService.prototype.saveLab_investigation = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'LabInvestigation/saveLabInvestigation', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('saveLabInvestigation'));
        }));
    };
    DoctorsService.prototype.saveDentalInvestigation = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'LabInvestigation/saveDentalInvestigation', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('saveDentalInvestigation'));
        }));
    };
    DoctorsService.prototype.saveInvestigation = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'LabInvestigation/saveInvestigation', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('saveInvestigation'));
        }));
    };
    DoctorsService.prototype.get_lab_investigation = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'LabInvestigation/getLabInvestigation', JSON.stringify(opData), httpOptions).pipe(tap(function (result) {
            return catchError(_this.handleError('getLabInvestigation'));
        }));
    };
    DoctorsService.prototype.getDentalInvestigation = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'LabInvestigation/getDentalInvestigation', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getDentalInvestigation'));
        }));
    };
    DoctorsService.prototype.deleteInvestigation = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'LabInvestigation/deleteInvestigation', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('deleteInvestigation'));
        }));
    };
    DoctorsService.prototype.getlabinvestigation = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'NewBill/getLabInvestigation', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getLabInvestigation'));
        }));
    };
    DoctorsService.prototype.getbillStatus = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'LabInvestigation/getbillStatus', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getbillStatus'));
        }));
    };
    DoctorsService.prototype.saveProcedurecode = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'ProcedureCode/saveProcedureCode', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('savecomplaints'));
        }));
    };
    DoctorsService.prototype.getProcedurecode = function (postdata) {
        return this.http.post(environment.API_ENDPOINT + '/ProcedureCode/listProcedureCode', null).pipe(map(this.extractData));
    };
    DoctorsService.prototype.saveDocuments = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'FileManage/saveFile', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('Document error'));
        }));
    };
    //For dental activities
    DoctorsService.prototype.saveLab_investigation_dental = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'LabInvestigation/saveLabInvestigation', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('saveLabInvestigation'));
        }));
    };
    DoctorsService.prototype.saveInvestigation_dental = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'LabInvestigation/saveInvestigation', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('saveInvestigation'));
        }));
    };
    DoctorsService.prototype.get_lab_investigation_dental = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'LabInvestigation/getLabInvestigation', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getLabInvestigation'));
        }));
    };
    DoctorsService.prototype.deleteInvestigation_dental = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'LabInvestigation/deleteInvestigation', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('deleteInvestigation'));
        }));
    };
    DoctorsService.prototype.getbillStatus_dental = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'LabInvestigation/getbillStatus', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getbillStatus'));
        }));
    };
    //To handle error
    DoctorsService.prototype.handleError = function (operation, result) {
        if (operation === void 0) { operation = 'operation'; }
        return function (error) {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead
            // TODO: better job of transforming error for user consumption
            console.log(operation + " failed: " + error.message);
            // Let the app keep running by returning an empty result.
            return of(result);
        };
    };
    DoctorsService.ngInjectableDef = i0.defineInjectable({ factory: function DoctorsService_Factory() { return new DoctorsService(i0.inject(i1.HttpClient)); }, token: DoctorsService, providedIn: "root" });
    return DoctorsService;
}());
export { DoctorsService };
