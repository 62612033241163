import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { environment } from '@env/environment';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
//const endpoint = 'http://192.168.0.11/hms/server/';
//const endpoint = 'http://wiktait.com/demo/hms/server/';
var EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
var EXCEL_EXTENSION = '.xlsx';
var httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};
var DrConsultationService = /** @class */ (function () {
    function DrConsultationService(http) {
        this.http = http;
    }
    DrConsultationService.prototype.extractData = function (res) {
        var body = res;
        return body || {};
    };
    DrConsultationService.prototype.getDropdowns = function () {
        return this.http.post(environment.API_ENDPOINT + 'DoctorSchedule/options', null).pipe(map(this.extractData));
    };
    DrConsultationService.prototype.addNewSchedule = function (opData) {
        return this.http.post(environment.API_ENDPOINT + 'DoctorSchedule/newSchedule', JSON.stringify(opData)).pipe(tap(function (result) { return console.log("added new etry w/ id=" + result.status); }), catchError(this.handleError('addNewOpRegistration')));
    };
    DrConsultationService.prototype.getScheduleList = function () {
        return this.http.post(environment.API_ENDPOINT + 'DoctorSchedule/getScheduleList', null).pipe(map(this.extractData));
    };
    DrConsultationService.prototype.getDoctorsSpecialized = function (postData) {
        return this.http.post(environment.API_ENDPOINT + 'DoctorSchedule/getDoctorsSpecialized', JSON.stringify(postData)).pipe(map(this.extractData));
    };
    DrConsultationService.prototype.getScheduleById = function (sendData) {
        return this.http.post(environment.API_ENDPOINT + 'DoctorSchedule/getScheduleById', JSON.stringify(sendData)).pipe(map(this.extractData));
    };
    DrConsultationService.prototype.deleteSchedule = function (sendData) {
        return this.http.post(environment.API_ENDPOINT + 'DoctorSchedule/deleteSchedule', JSON.stringify(sendData)).pipe(map(this.extractData));
    };
    DrConsultationService.prototype.exportAsExcelFile = function (json, excelFileName) {
        var worksheet = XLSX.utils.json_to_sheet(json);
        var workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        var excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, excelFileName);
    };
    DrConsultationService.prototype.saveAsExcelFile = function (buffer, fileName) {
        var data = new Blob([buffer], { type: EXCEL_TYPE });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    };
    //To handle error
    DrConsultationService.prototype.handleError = function (operation, result) {
        if (operation === void 0) { operation = 'operation'; }
        return function (error) {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead
            // TODO: better job of transforming error for user consumption
            console.log(operation + " failed: " + error.message);
            // Let the app keep running by returning an empty result.
            return of(result);
        };
    };
    DrConsultationService.ngInjectableDef = i0.defineInjectable({ factory: function DrConsultationService_Factory() { return new DrConsultationService(i0.inject(i1.HttpClient)); }, token: DrConsultationService, providedIn: "root" });
    return DrConsultationService;
}());
export { DrConsultationService };
